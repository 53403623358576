import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import _ from 'lodash';
import ModbusSettingDialog from 'js/components/ManageTemplates/ModbusTemplates/ModbusSettingDialog';
import Button from 'ecto-common/lib/Button/Button';
import AddButton from 'ecto-common/lib/Button/AddButton';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import {
  formatNumber,
  isNullOrWhitespace,
  searchByCaseInsensitive,
  Unit
} from 'ecto-common/lib/utils/stringUtils';

import moment from 'moment';

import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import APIGen, {
  AdminEquipmentSignalResponseModel,
  AdminEquipmentSignalType,
  ConnectionResponseModel,
  DeviceInfoResponseModel,
  NodeEquipmentResponseModel,
  SignalProviderType,
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import styles from 'js/components/ManageEquipment/EditEquipment/EditEquipmentSignals.module.css';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { DataTableFooter } from 'ecto-common/lib/DataTable/DataTableFooter';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import HorizontalAlignments from 'ecto-common/lib/types/HorizontalAlign';
import API, {
  CancellablePromiseCallback,
  cancellablePromiseList,
  cancellablePromiseSequence
} from 'ecto-common/lib/API/API';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import {
  handleModbusPropertyChange,
  transformModbusDataTypes,
  validateSignalCategoryChange
} from 'js/components/ModbusLayout/ModbusEditUtils';

import { signalProviderInputs } from 'js/components/ManageTemplates/signalInputs';
import UUID from 'uuidjs';
import { useSignalUpdateEventHubSubscription } from 'ecto-common/lib/EventHubConnection/EventHubConnectionHooks';

import {
  AdminEquipmentSignalWithConfigType,
  cleanSignalList,
  getAddressValue,
  getSignalConfigType,
  getSignalList,
  getSignalType,
  parseNumericValue,
  signalIsEnabled,
  signalIsModbus
} from 'js/components/ManageEquipment/EditEquipment/Util/editEquipmentUtil';
import Switch from 'ecto-common/lib/Switch/Switch';
import { useLiveEquipmentSignals } from 'ecto-common/lib/hooks/useLiveEquipmentSignals';
import SignalTypePicker from 'ecto-common/lib/SignalTypePicker/SignalTypePicker';
import { hasFalsyProperty } from 'ecto-common/lib/utils/functional';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { SignalValueType } from 'ecto-common/lib/hooks/useLatestSignalValues';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import Toolbar from 'ecto-common/lib/Toolbar/Toolbar';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarSearch from 'ecto-common/lib/Toolbar/ToolbarSearch';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import SignalValueEditModal from 'ecto-common/lib/SignalsTable/SignalValueEditModal';
import MessageDialog from 'ecto-common/lib/MessageDialog/MessageDialog';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import { getDefaultDateTimeFormatWithMilliseconds } from 'ecto-common/lib/utils/dateUtils';
import { getSignalTypeNameWithUnitFromMap } from 'ecto-common/lib/SignalSelector/SignalUtils';
import DeleteDataPointsModal from 'js/components/ManageEquipment/EditEquipment/DeleteDataPointsModal';
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';
import { usePromptMessage } from 'ecto-common/lib/hooks/useBlockerListener';

const addressIsValid = (value: string | number) =>
  value !== 0 && value != null && value !== '';

const getColumns = (
  connectionOptionsMap: Record<string, GenericSelectOption<string>>,
  connectionOptions: GenericSelectOption<string>[],
  signalUnitTypesMap: Record<string, UnitResponseModel>,
  signalTypesMap: Record<string, SignalTypeResponseModel>,
  signalValues: Record<string, SignalValueType>,
  pendingSignalIds: Record<string, boolean>,
  onChangeName: (
    signal: AdminEquipmentSignalWithConfigType,
    name: string
  ) => void,
  onChangeAddress: (
    signal: AdminEquipmentSignalWithConfigType,
    address: string
  ) => void,
  onChangeConnection: (
    signal: AdminEquipmentSignalWithConfigType,
    value: string
  ) => void,
  onEditSignalValue: (
    signal: AdminEquipmentSignalWithConfigType,
    value: string
  ) => void,
  onEditSignal: (signal: AdminEquipmentSignalWithConfigType) => void,
  onDeleteSignal: (signal: AdminEquipmentSignalWithConfigType) => void,
  onToggleSignal: (signal: AdminEquipmentSignalWithConfigType) => void,
  onDeleteSignalValues: (signal: AdminEquipmentSignalWithConfigType) => void,
  changeSignalProperty: (
    signal: AdminEquipmentSignalWithConfigType,
    key: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => void
): DataTableColumnProps<AdminEquipmentSignalWithConfigType>[] => [
  {
    label: T.admin.equipmentsignal.signaltype,
    dataKey: 'signalTypeId',
    minWidth: 190,
    maxWidth: 360,
    dataFormatter: (signalTypeId, signal) => {
      return (
        <SignalTypePicker
          value={signal.signalTypeId}
          showIcon={false}
          onChange={(newValue) => {
            changeSignalProperty(signal, 'signalTypeId', newValue);
          }}
          hasError={_.isNil(signalTypeId)}
        />
      );
    }
  },
  {
    label: T.admin.equipmentsignal.displayname,
    dataKey: 'name',
    minWidth: 100,
    dataFormatter: (signalName: string, signal) => {
      return (
        <TextInput
          value={signalName}
          onChange={(ev) => onChangeName(signal, ev.target.value)}
          error={isNullOrWhitespace(signalName)}
        />
      );
    }
  },
  {
    label: T.admin.equipmentsignal.type,
    dataKey: 'unused1',
    minWidth: 100,
    maxWidth: 200,
    dataFormatter: (unused, signal) => {
      return signalIsEnabled(signal) && getSignalType(signal);
    }
  },
  {
    label: T.admin.equipmentsignal.address,
    dataKey: 'unused2',
    minWidth: 84,
    maxWidth: 170,
    dataFormatter: (unused, signal) => {
      if (!signalIsModbus(signal) || !signalIsEnabled(signal)) {
        return null;
      }

      const value = getAddressValue(signal);

      return (
        <TextInput
          value={value}
          error={!addressIsValid(value)}
          onChange={(ev) => onChangeAddress(signal, ev.target.value)}
        />
      );
    }
  },
  {
    label: T.admin.equipmentsignal.connection,
    dataKey: '_connection',
    minWidth: 140,
    maxWidth: 500,
    dataFormatter: (unused, signal) => {
      return (
        <Select
          placeholder={''}
          value={connectionOptionsMap[signal.connectionId]}
          options={connectionOptions}
          onChange={(opt: GenericSelectOption) =>
            onChangeConnection(signal, opt.value)
          }
        />
      );
    }
  },
  {
    label: T.admin.equipmentsignal.value,
    dataKey: 'unused4',
    minWidth: 90,
    maxWidth: 110,
    align: HorizontalAlignments.CENTER,
    dataFormatter: (unused, signal) => {
      const value = signalValues[signal.signalId];
      let content;

      if (signal.isWritable) {
        const isLoading = pendingSignalIds[signal.signalId];
        const signalType = signalTypesMap[signal.signalTypeId];
        const unit = signalUnitTypesMap[signalType?.unitId];

        if (unit?.unit === Unit.BINARY) {
          const isOn = value?.value === 1;
          content = (
            <Switch
              isOn={isOn}
              onClick={() => onToggleSignal(signal)}
              isLoading={isLoading}
            />
          );
        } else {
          content = (
            <Button
              className={styles.editSignalButton}
              loading={isLoading}
              onClick={() =>
                onEditSignalValue(
                  signal,
                  value ? formatNumber(value.value) : null
                )
              }
            >
              {value ? formatNumber(value.value) : '-'}
            </Button>
          );
        }
      } else {
        content = value ? formatNumber(value.value) : '-';
      }

      return value ? (
        <Tooltip
          text={moment(value.time)
            .tz(DEFAULT_TIMEZONE)
            .format(getDefaultDateTimeFormatWithMilliseconds())}
        >
          {content}
        </Tooltip>
      ) : (
        <Tooltip text={T.equipment.novalueset}>{content}</Tooltip>
      );
    }
  },
  ...standardColumns({
    onEdit: onEditSignal,
    onDelete: onDeleteSignal,
    extraButtons:
      onDeleteSignalValues != null
        ? [
            {
              icon: <Icons.Eraser />,
              action: onDeleteSignalValues,
              tooltipText: T.admin.equipment.deletedatapoint.buttontitle
            }
          ]
        : [],
    shouldDisableDelete: (signal: AdminEquipmentSignalWithConfigType) =>
      !(
        signal.type === AdminEquipmentSignalType.Alarm ||
        signal.type === AdminEquipmentSignalType.Equipment
      )
  })
];

const getDataPromise = (
  contextSettings: ApiContextSettings,
  equipmentId: string
): Promise<
  [AdminEquipmentSignalResponseModel[], string, ConnectionResponseModel[]]
> => {
  return cancellablePromiseSequence(
    (withNextPromise: CancellablePromiseCallback) => {
      return withNextPromise(
        cancellablePromiseList([
          API.Admin.Signals.getEquipmentSignals(contextSettings, [equipmentId]),
          API.Admin.Signals.getAlarmSignals(contextSettings, [equipmentId]),
          API.Admin.Devices.getDeviceInfoByEquipmentIds(contextSettings, [
            equipmentId
          ])
        ])
      ).then(
        ([equipmentSignals, alarmSignals, deviceInfo]: [
          AdminEquipmentSignalResponseModel[],
          AdminEquipmentSignalResponseModel[],
          DeviceInfoResponseModel[]
        ]) => {
          const deviceId = _.head(deviceInfo)?.deviceId;

          return withNextPromise(
            cancellablePromiseList([
              Promise.resolve(equipmentSignals.concat(alarmSignals)),
              Promise.resolve(deviceId),
              deviceId != null
                ? API.Admin.Devices.getConnectionsByDeviceIds(contextSettings, [
                    deviceId
                  ])
                : Promise.resolve([])
            ])
          );
        }
      );
    }
  );
};

const saveDataPromise = (
  contextSettings: ApiContextSettings,
  signals: AdminEquipmentSignalWithConfigType[]
) => {
  const eqSignals = cleanSignalList(
    _.filter(signals, ['type', SignalProviderType.Equipment])
  );
  const alarmSignals = cleanSignalList(
    _.filter(signals, ['type', SignalProviderType.Alarm])
  );
  const modEqSignals = transformModbusDataTypes(_.cloneDeep(eqSignals));
  const modAlarmSignals = transformModbusDataTypes(_.cloneDeep(alarmSignals));

  if (modEqSignals.length === 0 && modAlarmSignals.length === 0) {
    return Promise.resolve([]);
  }

  const promises = [];

  if (modEqSignals.length > 0) {
    // Need to make sure signalCategoryIds is not null to bridge over API model differences
    promises.push(
      API.Admin.Signals.addOrUpdateEquipmentSignals(
        contextSettings,
        modEqSignals.map((x) => ({
          ...x,
          signalCategoryIds: x.signalCategoryIds ?? []
        }))
      )
    );
  }

  if (modAlarmSignals.length > 0) {
    promises.push(
      API.Admin.Signals.addOrUpdateAlarmSignals(
        contextSettings,
        modAlarmSignals.map((x) => ({
          ...x,
          signalCategoryIds: x.signalCategoryIds ?? []
        }))
      )
    );
  }

  return cancellablePromiseList(promises);
};

interface EditEquipmentSignalsProps {
  equipment?: NodeEquipmentResponseModel;
}

const EditEquipmentSignals = ({ equipment }: EditEquipmentSignalsProps) => {
  const featureFlagState = useSyncExternalStore(
    featureFlagStore.subscribe,
    featureFlagStore.getSnapshot
  );
  const useEIoT = featureFlagState['eiot-signals'] === true;

  const equipmentMap = useAdminSelector((state) => state.general.equipmentMap);
  const nodeMap = useAdminSelector((state) => state.general.nodeMap);
  const signalUnitTypesMap = useAdminSelector(
    (state) => state.general.signalUnitTypesMap
  );
  const signalTypesMap = useAdminSelector(
    (state) => state.general.signalTypesMap
  );
  const enums = useAdminSelector((state) => state.general.enums);

  const [signalValues, setSignalValues] = useState<
    Record<string, SignalValueType>
  >({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [valueEditingSignal, setValueEditingSignal] =
    useState<AdminEquipmentSignalWithConfigType>(null);
  const [selectedSignal, setSelectedSignal] =
    useState<AdminEquipmentSignalWithConfigType>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [newSignal, setNewSignal] = useState<any>(null);
  const [signalToDelete, setSignalToDelete] =
    useState<AdminEquipmentSignalWithConfigType>(null);
  const [signalToDeleteValues, setSignalToDeleteValues] =
    useState<AdminEquipmentSignalWithConfigType>(null);

  const clearSignalToDeleteValues = useCallback(() => {
    setSignalToDeleteValues(null);
  }, []);

  const [signals, setSignals] = useState<AdminEquipmentSignalWithConfigType[]>(
    []
  );
  const [signalIds, setSignalIds] = useState<string[]>([]);
  const [pendingSignalIds, setPendingSignalIds] = useState<
    Record<string, boolean>
  >({});
  const [connections, setConnections] = useState<
    Record<string, ConnectionResponseModel>
  >({});
  const [loadDataFailed, setLoadDataFailed] = useState(false);

  const onSignalValuesChanged = useCallback((values: SignalValueType[]) => {
    const valueSignalIds = _.map(values, 'signalId');
    setSignalValues((oldSignalValues) => ({
      ...oldSignalValues,
      ..._.keyBy(values, 'signalId')
    }));

    setPendingSignalIds((oldPendingSignalIds) =>
      _.omit(oldPendingSignalIds, valueSignalIds)
    );
  }, []);

  useLiveEquipmentSignals(
    useMemo(() => [equipment.equipmentId], [equipment.equipmentId])
  );

  useSignalUpdateEventHubSubscription(null, signalIds, onSignalValuesChanged);

  const [isLoadingData, loadData, cancelLoadData] = usePromiseCall({
    promise: getDataPromise,
    onSuccess: ([allSignals, , newConnections]) => {
      const mappedConnections = _.keyBy(newConnections, 'id');
      setSignals(getSignalList(allSignals, mappedConnections));
      setSignalIds(_.map(allSignals, 'signalId'));
      setConnections(mappedConnections);
    },
    onError: () => {
      setLoadDataFailed(true);
    }
  });

  const [isDeletingSignal, deleteSignal] = usePromiseCall({
    promise: API.Signals.deleteSignals,
    onSuccess: (unused, deletedSignalIds) => {
      const newSignals = _.reject<AdminEquipmentSignalWithConfigType>(
        signals,
        (x) => deletedSignalIds.includes(x.signalId)
      );
      setSignals(newSignals);
      setSignalIds(_.map(newSignals, 'signalId'));
      setSignalToDelete(null);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.equipment.deletesignal.error);
    }
  });

  const [isSavingData, saveData] = usePromiseCall({
    promise: saveDataPromise,
    onSuccess: (unused, newSignals) => {
      setSignals(newSignals);
      setSignalIds(_.map(newSignals, 'signalId'));
      setNewSignal(null);
      setHasUnsavedChanges(false);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.requests.updatesignals.failure);
    }
  });

  const setSignalMutation = APIGen.Devices.setSignalWithAudit.useMutation({
    onMutate: (request) => {
      setPendingSignalIds((oldPendingSignalIds) => ({
        ...oldPendingSignalIds,
        [request.equipmentSignalId]: true
      }));
    },
    onSuccess: (result) => {
      if (result.success === false) {
        if (result.deviceName === '') {
          toastStore.addErrorToast(T.equipment.setvaluefailurenodevice);
        } else {
          toastStore.addErrorToast(T.equipment.setvaluefailurenoconnection);
        }

        // Clear pending signal, the server will not report any change on this signal.
        setPendingSignalIds((oldPendingSignalIds) =>
          _.omit(oldPendingSignalIds, [result.signalId])
        );
      }
      // pending signals will be cleared once the signalR is triggered and the server reports signal changed

      setValueEditingSignal(null);
    },
    onError: (_unused, request) => {
      toastStore.addErrorToast(T.admin.equipmentsignal.failedtosetsignal);
      // Clear pending signal, the server will not report any change on this signal.
      setPendingSignalIds((oldPendingSignalIds) =>
        _.omit(oldPendingSignalIds, [request.equipmentSignalId])
      );
    }
  });

  const connectionOptions: GenericSelectOption<string>[] = useMemo(
    () => [
      { value: undefined, label: T.admin.equipmentsignal.noconnection },
      ..._.map(connections, (connection) => {
        // Find Energy Manager equipment that represents connection to get option name
        const eq = getNodeFromMap(equipmentMap, connection.id);
        const parent = getNodeFromMap(nodeMap, eq?.nodeId);
        let eqName = eq.name ?? T.common.notavailable;

        if (parent != null) {
          eqName += ' - ' + parent.name;
        }

        return {
          value: connection.id,
          label: eqName
        };
      })
    ],
    [connections, equipmentMap, nodeMap]
  );

  const connectionOptionsMap: Record<
    string,
    GenericSelectOption<string>
  > = useMemo(() => _.keyBy(connectionOptions, 'value'), [connectionOptions]);

  useEffect(() => {
    if (equipment != null) {
      setHasUnsavedChanges(false);
      loadData(equipment.equipmentId);
    }

    return () => {
      cancelLoadData();
    };
  }, [equipment, loadData, cancelLoadData]);

  const onEditSignal = useCallback(
    (signal: AdminEquipmentSignalWithConfigType) => {
      setSelectedSignal(_.cloneDeep(signal));
    },
    []
  );

  const onEditSignalValue = useCallback(
    (signal: AdminEquipmentSignalWithConfigType) => {
      setValueEditingSignal(signal);
    },
    []
  );

  const onChangeSignalType = useCallback(
    (data: GenericSelectOption<SignalProviderType>) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setNewSignal((prevState: any) => {
        if (data.value === prevState.type) {
          return prevState;
        }

        // When changing provider types, remove no longer relevant fields. Add default values for
        // fields that are now relevant. Also, replace previous value of signalCategoryIds to suit
        // the new type better.
        const signalInputs = signalProviderInputs[data.value].inputs(enums);
        const prevKeys = _.keys(prevState);
        const validKeys = _.map(signalInputs, 'type').concat([
          'type',
          'signalSettings',
          'configType'
        ]); // These are set outside of inputs, keep them safe from overwrites
        const noLongerValidKeys = _.filter(
          prevKeys,
          (oldKey) => !validKeys.includes(oldKey)
        );
        const missingKeys = _.filter(
          validKeys,
          (validKey) => !prevKeys.includes(validKey)
        ).concat('signalCategoryIds'); // Remove signal category ID:s so they are reloaded from defaults
        const missingDefaultValues = _.pickBy(
          signalProviderInputs[data.value].emptySignal,
          (_value, key) => {
            return missingKeys.includes(key);
          }
        );

        return {
          ..._.pickBy(
            prevState,
            (_value, key) => !noLongerValidKeys.includes(key)
          ),
          type: data.value,
          ...missingDefaultValues
        };
      });
    },
    [enums]
  );

  const onConfirmCurrentEditSignalValue = useCallback(
    (
      signal: AdminEquipmentSignalWithConfigType,
      value: number,
      oldValue: number,
      message: string
    ) => {
      setSignalMutation.mutate({
        equipmentSignalId: signal.signalId,
        value,
        oldValue,
        message
      });
    },
    [setSignalMutation]
  );

  const confirmDeleteSignal = useCallback(() => {
    deleteSignal([signalToDelete.signalId]);
  }, [deleteSignal, signalToDelete]);

  const commitSignal = useCallback(
    (updatedSignal: AdminEquipmentSignalWithConfigType) => {
      setSignals((oldSignals) => {
        const newSignals = [...oldSignals];
        const updateIndex = _.findIndex(oldSignals, [
          'signalId',
          updatedSignal.signalId
        ]);
        newSignals[updateIndex] = updatedSignal;
        return newSignals;
      });
    },
    []
  );

  const offsetEditSignal = useCallback(
    (offset: number) => {
      const selectedSignalIndex = _.findIndex(signals, [
        'signalId',
        selectedSignal.signalId
      ]);
      let newIndex = selectedSignalIndex + offset;

      if (newIndex < 0) {
        newIndex = signals.length - 1;
      } else if (newIndex >= signals.length) {
        newIndex = 0;
      }

      commitSignal(selectedSignal);
      setSelectedSignal(signals[newIndex]);
    },
    [signals, selectedSignal, commitSignal]
  );

  const nextEditSignal = useCallback(
    () => offsetEditSignal(1),
    [offsetEditSignal]
  );

  const prevEditSignal = useCallback(
    () => offsetEditSignal(-1),
    [offsetEditSignal]
  );

  const stopEditingSignal = useCallback(() => {
    if (selectedSignal != null) {
      commitSignal(selectedSignal);
    }

    setSelectedSignal(null);
    setNewSignal(null);
  }, [selectedSignal, commitSignal]);

  const updateSignal = useCallback(
    (updatedSignal: AdminEquipmentSignalWithConfigType) => {
      if (newSignal != null) {
        setNewSignal(updatedSignal);
      } else {
        setHasUnsavedChanges(true);
        if (selectedSignal != null) {
          setSelectedSignal(updatedSignal);
        } else {
          commitSignal(updatedSignal);
        }
      }
    },
    [newSignal, selectedSignal, commitSignal]
  );

  const changeSignalProperty = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (signal: AdminEquipmentSignalWithConfigType, name: string, value: any) => {
      const updatedSignal = _.cloneDeep(signal);
      _.set(updatedSignal, name, value);
      updatedSignal.configType = getSignalConfigType(
        updatedSignal,
        connections
      );
      updateSignal(updatedSignal);
    },
    [updateSignal, connections]
  );

  const onChangeName = useCallback(
    (signal: AdminEquipmentSignalWithConfigType, name: string) => {
      changeSignalProperty(signal, 'name', name);
    },
    [changeSignalProperty]
  );

  const onChangeAddress = useCallback(
    (signal: AdminEquipmentSignalWithConfigType, address: string) => {
      const signalSettings = { ...signal.signalSettings };
      signalSettings.modbusAddress = parseNumericValue(address);
      if (signalSettings.modbusAddress == null) {
        delete signalSettings.modbusAddress;
      }

      updateSignal({ ...signal, signalSettings });
    },
    [updateSignal]
  );

  const onChangeConnection = useCallback(
    (signal: AdminEquipmentSignalWithConfigType, connectionId: string) => {
      changeSignalProperty(signal, 'connectionId', connectionId);
    },
    [changeSignalProperty]
  );

  const curEditSignal = newSignal ?? selectedSignal;

  const onChangeSignalProperty = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (name: string, value: any) => {
      if (
        !validateSignalCategoryChange(
          curEditSignal,
          curEditSignal.type,
          name,
          value
        )
      ) {
        return;
      }

      const updatedSignal = _.cloneDeep(curEditSignal);
      _.set(updatedSignal, name, value);
      updatedSignal.configType = getSignalConfigType(
        updatedSignal,
        connections
      );

      if (name === 'signalTypeId') {
        const prevSignalType = signalTypesMap[curEditSignal.signalTypeId];
        const signalType = signalTypesMap[value as string];

        if (signalType != null) {
          if (
            isNullOrWhitespace(curEditSignal.name) ||
            curEditSignal.name === prevSignalType?.name
          ) {
            _.set(updatedSignal, 'name', signalType.name);
          }

          if (
            isNullOrWhitespace(curEditSignal.description) ||
            curEditSignal.description === prevSignalType?.description
          ) {
            _.set(updatedSignal, 'description', signalType.description);
          }
        }
      }

      updateSignal(updatedSignal);
    },
    [curEditSignal, connections, updateSignal, signalTypesMap]
  );

  const onChangeModbusProperty = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (name: string, value: any) => {
      const updatedSignal = _.cloneDeep(curEditSignal);
      updatedSignal.configType = getSignalConfigType(
        updatedSignal,
        connections
      );
      let modbus = _.cloneDeep(
        updatedSignal.signalSettings[updatedSignal.configType]
      );
      modbus = handleModbusPropertyChange(modbus, name, value);
      updatedSignal.signalSettings[updatedSignal.configType] = modbus;

      updateSignal(updatedSignal);
    },
    [curEditSignal, updateSignal, connections]
  );

  const onAddSignal = useCallback(() => {
    setNewSignal({
      ..._.cloneDeep(
        signalProviderInputs[SignalProviderType.Equipment].emptySignal
      ),
      type: AdminEquipmentSignalType.Equipment
    });
  }, []);

  const _saveData = useCallback(
    (_signals: AdminEquipmentSignalWithConfigType[]) => {
      if (_.some(_signals, hasFalsyProperty('signalTypeId', 'name'))) {
        toastStore.addErrorToast(
          T.admin.equipmenttemplates.error.missingfields
        );
      } else {
        saveData(_signals);
      }
    },
    [saveData]
  );

  const onSaveNewSignal = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_providerTypeUnused: SignalProviderType, signal: any) => {
      const newSignalFull = {
        ...signal,
        equipmentId: equipment.equipmentId,
        signalId: UUID.generate()
      };

      _saveData(signals.concat([newSignalFull]));
    },
    [equipment.equipmentId, _saveData, signals]
  );

  const onSave = useCallback(() => {
    _saveData(signals);
  }, [signals, _saveData]);

  const [
    toggleSignalMessageDialogOpen,
    showToggleSignalMessageDialog,
    hideToggleSignalMessageDialog
  ] = useSimpleDialogState();
  const signalWithMessage =
    React.useRef<AdminEquipmentSignalWithConfigType>(null);

  const onToggleSignal = useCallback(
    (signal: AdminEquipmentSignalWithConfigType) => {
      signalWithMessage.current = signal;
      showToggleSignalMessageDialog();
    },
    [showToggleSignalMessageDialog]
  );

  const onConfirmToggleSignalMessage = useCallback(
    (message: string) => {
      const signal = signalWithMessage.current;
      if (signal != null) {
        const oldValue = signalValues[selectedSignal?.signalId]?.value ?? 0;
        const value = oldValue === 1.0 ? 0.0 : 1.0;
        setSignalMutation.mutate({
          equipmentSignalId: signal.signalId,
          value,
          oldValue,
          message
        });
      }
    },
    [selectedSignal?.signalId, setSignalMutation, signalValues]
  );

  const onDeleteSignalValues = useCallback(
    (signal: AdminEquipmentSignalWithConfigType) => {
      setSignalToDeleteValues(signal);
    },
    []
  );

  const columns: DataTableColumnProps<AdminEquipmentSignalWithConfigType>[] =
    useMemo(
      () =>
        getColumns(
          connectionOptionsMap,
          connectionOptions,
          signalUnitTypesMap,
          signalTypesMap,
          signalValues,
          pendingSignalIds,
          onChangeName,
          onChangeAddress,
          onChangeConnection,
          onEditSignalValue,
          onEditSignal,
          setSignalToDelete,
          onToggleSignal,
          useEIoT ? onDeleteSignalValues : null,
          changeSignalProperty
        ),
      [
        connectionOptionsMap,
        connectionOptions,
        signalUnitTypesMap,
        signalTypesMap,
        signalValues,
        pendingSignalIds,
        onChangeName,
        onChangeAddress,
        onChangeConnection,
        onEditSignalValue,
        onEditSignal,
        onToggleSignal,
        useEIoT,
        onDeleteSignalValues,
        changeSignalProperty
      ]
    );

  const [searchString, setSearchString] = useState('');
  const onSearch = useCallback((newSearchString: string) => {
    setSearchString(newSearchString);
  }, []);

  const filteredSignals = useMemo(
    () =>
      searchByCaseInsensitive(signals, searchString, 'name', (input) =>
        getSignalTypeNameWithUnitFromMap(
          input.signalTypeId,
          signalTypesMap,
          signalUnitTypesMap
        )
      ),
    [searchString, signalTypesMap, signalUnitTypesMap, signals]
  );

  const [currentValue, currentRawValue] = useMemo(() => {
    if (selectedSignal == null) {
      return ['', null];
    }
    const currentSignalData = signalValues[selectedSignal?.signalId];
    const dataPoint = currentSignalData?.value;
    return [dataPoint != null ? formatNumber(dataPoint) : '', dataPoint];
  }, [selectedSignal, signalValues]);

  usePromptMessage(T.admin.form.unsavedstate, hasUnsavedChanges);

  if (loadDataFailed) {
    return (
      <div>
        <ErrorNotice>{T.admin.equipment.configerror}</ErrorNotice>
      </div>
    );
  }

  return (
    <>
      <Toolbar>
        <ToolbarFlexibleSpace />
        <ToolbarItem>
          <ToolbarSearch value={searchString} onChange={onSearch} />
        </ToolbarItem>
      </Toolbar>
      <DataTable<AdminEquipmentSignalWithConfigType>
        isLoading={isLoadingData}
        data={filteredSignals}
        columns={columns}
      />
      <DataTableFooter alignRight>
        <AddButton onClick={onAddSignal}>
          {T.admin.equipmenttemplates.addsignal}
        </AddButton>
        <LocalizedButtons.Save
          disabled={!hasUnsavedChanges || isSavingData}
          onClick={onSave}
        />
      </DataTableFooter>
      <ActionModal
        compact
        onModalClose={() => setSignalToDelete(null)}
        isOpen={signalToDelete != null}
        isLoading={isDeletingSignal}
        headerIcon={Icons.Delete}
        title={T.admin.equipment.confirmdelete.title}
        actionText={T.common.delete}
        onConfirmClick={confirmDeleteSignal}
      >
        {T.format(
          T.admin.equipment.confirmdeletesignal.message,
          signalToDelete?.name
        )}
      </ActionModal>
      <ModbusSettingDialog
        onChangeSignalProperty={onChangeSignalProperty}
        onChangeModbusProperty={onChangeModbusProperty}
        modbusMode={_.get(curEditSignal, 'configType')}
        onChangeConfigType={() => {}}
        onChangeSignalType={onChangeSignalType}
        disableConfigTypeSelector
        selectedSignal={curEditSignal}
        connections={connections}
        onPrevClicked={prevEditSignal}
        onNextClicked={nextEditSignal}
        onModalClose={stopEditingSignal}
        isOpen={curEditSignal != null}
        signals={signals}
        addSignal={onSaveNewSignal}
        addSignalIsLoading={isSavingData}
        isEditingSignal={newSignal === null}
      />
      <MessageDialog
        isOpen={toggleSignalMessageDialogOpen}
        title={T.editsignalvalue.dialogtitle}
        messageTitle={T.common.reason}
        onModalClose={hideToggleSignalMessageDialog}
        onConfirmMessage={onConfirmToggleSignalMessage}
        isRequired
      />

      <SignalValueEditModal
        signal={valueEditingSignal}
        isOpen={valueEditingSignal != null}
        currentValue={currentValue}
        currentRawValue={currentRawValue}
        onCancel={() => setValueEditingSignal(null)}
        onConfirm={onConfirmCurrentEditSignalValue}
        isLoading={setSignalMutation.isLoading}
      />
      <DeleteDataPointsModal
        signal={signalToDeleteValues}
        onModalClose={clearSignalToDeleteValues}
      />
    </>
  );
};

export default EditEquipmentSignals;
