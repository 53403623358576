import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';

import Spinner from 'ecto-common/lib/Spinner/Spinner';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import API from 'ecto-common/lib/API/API';

import EditEnergyManagerSettings from 'js/components/EditBuildingData/EditEnergyManagerSettings';
import ModbusConnectionDialog from 'js/components/EditBuildingData/ModbusConnectionDialog';
import { handleModbusConnectionChange } from 'js/components/ModbusLayout/ModbusEditUtils';
import styles from 'js/components/ManageEquipment/EditEnergyManager/EditEnergyManager.module.css';
import {
  AddOrUpdateConnectionRequestModel,
  ConnectionResponseModel,
  EquipmentResponseModel
} from 'ecto-common/lib/API/APIGen';

interface EditEnergyManagerProps {
  equipment?: EquipmentResponseModel;
}

const EditEnergyManager = ({ equipment }: EditEnergyManagerProps) => {
  const [connectionData, setConnectionData] =
    useState<ConnectionResponseModel>(null);
  const [originalConnectionData, setOriginalConnectionData] =
    useState<ConnectionResponseModel>(null);

  const [showingEditConnection, showEditConnection, hideEditConnection] =
    useDialogState('edit-em-settings');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const onClose = useCallback(() => {
    setConnectionData(originalConnectionData);
    setHasUnsavedChanges(false);
    hideEditConnection();
  }, [hideEditConnection, setConnectionData, originalConnectionData]);

  const [getConnectionsIsLoading, getConnections] = usePromiseCall({
    promise: API.Admin.Devices.getConnections,
    onSuccess: (response) => {
      const connection = response[0];
      setConnectionData(connection);
      setOriginalConnectionData(connection);
      setHasUnsavedChanges(false);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.equipment.request.updatetoolfailed);
    }
  });

  const getEnergyManagerEquipmentById = useCallback(
    (equipmentId: string) => {
      getConnections([equipmentId ?? connectionData?.id]);
    },
    [connectionData?.id, getConnections]
  );

  useEffect(() => {
    getEnergyManagerEquipmentById(equipment?.equipmentId);
  }, [equipment?.equipmentId, getEnergyManagerEquipmentById]);

  const onChangeConnectionProperty = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string, value: any) => {
      const _connectionData = Object.assign({}, { ...connectionData });
      _connectionData.connectionModbusConfig = Object.assign(
        {},
        connectionData.connectionModbusConfig
      );

      handleModbusConnectionChange(
        _connectionData.connectionModbusConfig,
        key,
        value
      );
      setHasUnsavedChanges(!_.isEqual(_connectionData, originalConnectionData));

      setConnectionData(_connectionData);
    },
    [connectionData, originalConnectionData]
  );

  const [setConnectionIsLoading, setConnection] = usePromiseCall({
    promise: API.Admin.Devices.setConnection,
    onSuccess: () => {
      toastStore.addSuccessToast(T.admin.requests.updatedeviceconfig.success);
      getEnergyManagerEquipmentById(equipment?.equipmentId);
      hideEditConnection();
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.requests.updatedeviceconfig.failure);
    }
  });

  const isLoading = getConnectionsIsLoading;

  const saveButtonEnabled = hasUnsavedChanges;

  const onSave = useCallback(() => {
    // Cast due to non-required members in ConnectionResponse
    setConnection(connectionData as AddOrUpdateConnectionRequestModel);
  }, [connectionData, setConnection]);

  return (
    <div>
      {isLoading && <Spinner />}

      {!isLoading && connectionData != null && (
        <div>
          <div className={styles.sectionSpacer} />
          <LoadingContainer isLoading={isLoading}>
            <EditEnergyManagerSettings
              connectionData={connectionData}
              onEditConnection={showEditConnection}
            />

            <ModbusConnectionDialog
              selectedConnection={connectionData.connectionModbusConfig}
              onChangeProperty={onChangeConnectionProperty}
              onModalClose={onClose}
              showSaveButton
              disableClose={setConnectionIsLoading}
              saveButtonEnabled={saveButtonEnabled}
              onSave={onSave}
              isOpen={showingEditConnection}
              isLoading={setConnectionIsLoading}
            />
          </LoadingContainer>
        </div>
      )}
    </div>
  );
};

export default EditEnergyManager;
