import React, { useCallback, useContext, useMemo } from 'react';

import T from 'ecto-common/lib/lang/Language';
import { useParams, generatePath } from 'react-router-dom';
import IntegrationAPIGen from 'ecto-common/lib/API/IntegrationAPIGen';
import { NavLink } from 'react-router-dom';

import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { ItemPageParams } from 'js/utils/routeConstants';

import {
  configurationObjectWithConfiguration,
  integrationModelsWithConfiguration
} from 'js/components/Integrations/IntegrationModels';
import { IntegrationPointResponse } from 'ecto-common/lib/API/IntegrationAdminAPIGen';
import IntegrationAdminAPIGen from 'ecto-common/lib/API/IntegrationAdminAPIGen';
import { useMutation } from '@tanstack/react-query';
import { IntegrationsRoute } from 'js/utils/routeConstants';
import CRUDView, { ODataListQuery } from 'ecto-common/lib/CRUDView/CRUDView';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import { useSelector } from 'react-redux';
import { CommonRootState } from 'ecto-common/lib/reducers/storeCommon';
import { getColumns } from './IntegrationPointTable';

const IntegrationProxyPage = () => {
  const params = useParams<ItemPageParams>();
  const { itemId } = params;
  const { tenantId, contextSettings } = useContext(TenantContext);

  const deleteItemMutation = useMutation((item: IntegrationPointResponse) =>
    IntegrationAdminAPIGen.IntegrationPoints.deleteIntegrationPoint.promise(
      contextSettings,
      {
        pointId: item?.id
      },
      null
    )
  );

  const { data: integrationProxy } =
    IntegrationAPIGen.IntegrationProxies.getIntegrationProxy.useQuery({
      proxyId: itemId
    });

  const models = useMemo(() => {
    return integrationModelsWithConfiguration(
      integrationProxy?.configuration,
      true
    );
  }, [integrationProxy]);

  const listQueryHook = (query: ODataListQuery) =>
    IntegrationAPIGen.IntegrationProxies.listIntegrationPointsForIntegrationProxy.useInfiniteQuery(
      { proxyId: itemId },
      query
    );
  const createItemMutation =
    IntegrationAdminAPIGen.IntegrationPoints.createIntegrationPoint.useMutation(
      {}
    );

  const updateItemMutation = useMutation((item: IntegrationPointResponse) =>
    IntegrationAdminAPIGen.IntegrationPoints.updateIntegrationPoint.promise(
      contextSettings,
      { pointId: item?.id },
      item,
      null
    )
  );

  const nodeMap = useSelector(
    (state: CommonRootState) => state.general.nodeMap
  );

  const columns: DataTableColumnProps<IntegrationPointResponse>[] = useMemo(
    () => getColumns(nodeMap, tenantId),
    [nodeMap, tenantId]
  );

  const createNewItem = useCallback(() => {
    return {
      integrationProxyId: integrationProxy?.id,
      name: '',
      nodeIds: [],
      configuration: configurationObjectWithConfiguration(
        integrationProxy?.configuration
      ),
      id: null
    };
  }, [integrationProxy?.configuration, integrationProxy?.id]);
  return (
    <CRUDView
      models={models}
      columns={columns}
      createNewItem={createNewItem}
      itemName={'name'}
      searchItems={['name']}
      sortBy={'name'}
      title={
        <>
          <NavLink to={generatePath(IntegrationsRoute.path, { tenantId })}>
            {T.admin.tabs.integrations}
          </NavLink>
          &nbsp;&gt;&nbsp;{integrationProxy && integrationProxy.name}
        </>
      }
      editTitle={T.format(
        T.admin.integrations.editpointmodalformat,
        integrationProxy?.name
      )}
      addTitle={T.admin.integrations.addpoint}
      listQueryHook={listQueryHook}
      deleteItemMutation={deleteItemMutation}
      updateItemMutation={updateItemMutation}
      createItemMutation={createItemMutation}
    />
  );
};

IntegrationProxyPage.propTypes = {};

export default React.memo(IntegrationProxyPage);
