import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import _ from 'lodash';

import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';

import EditNotificationDialog from './EditNotificationDialog';
import { NotificationModelsWithNodePicker } from './NotificationModels';
import ModelDisplay from 'ecto-common/lib/ModelForm/ModelDisplay';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import API from 'ecto-common/lib/API/API';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { AlarmNotificationResponseModel } from 'ecto-common/lib/API/APIGen';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { getPathStringFromModelKeyFunc } from 'ecto-common/lib/ModelForm/formUtils';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';

const getNotifications = (
  contextSettings: ApiContextSettings,
  nodeId: string
) => {
  if (nodeId) {
    return API.Admin.Notifications.notificationForNodeIds(contextSettings, [
      nodeId
    ]);
  }
  return API.Admin.Notifications.allNotifications(contextSettings);
};

const deleteNotification = (
  contextSettings: ApiContextSettings,
  item: AlarmNotificationResponseModel
) => API.Admin.Notifications.deleteNotifications(contextSettings, [item.id]);

interface NotificationTableProps {
  nodeId?: string;
  searchString?: string;
  editItemId?: string;
  setEditItemId: (id: string, replace?: boolean) => void;
}

const NotificationTable = ({
  nodeId,
  searchString,
  editItemId,
  setEditItemId
}: NotificationTableProps) => {
  const nodeMap = useAdminSelector((state) => state.general.nodeMap);

  const [confirmDeleteId, setConfirmDeleteId] = useSearchParamState(
    'delete-notification-id',
    null
  );
  const [notifications, setNotifications] = useState<
    AlarmNotificationResponseModel[]
  >([]);
  const [hasError, setError] = useState(false);

  const [isLoadingItems, _getItems] = usePromiseCall({
    promise: getNotifications,
    onSuccess: (result) => setNotifications(result.alarmNotifications),
    onError: () => {
      setNotifications([]);
      setError(true);
    },
    initiallyLoading: true
  });

  const getItems = useCallback(() => {
    setError(false);
    _getItems(nodeId);
  }, [nodeId, _getItems]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const [isCreatingItem, createItem] = usePromiseCall({
    promise: API.Admin.Notifications.addNotification,
    onSuccess: (unused, item) => {
      const isNew = editItemId === 'new';

      toastStore.addSuccessToastForUpdatedItem(item.name, isNew);
      getItems();
      setEditItemId(null, isNew);
    },
    onError: (unused, item) => {
      toastStore.addErrorToastForUpdatedItem(item.name, editItemId === 'new');
    }
  });

  const [isDeletingItem, deleteItem] = usePromiseCall({
    promise: deleteNotification,
    onSuccess: (unused, item) => {
      toastStore.addSuccessToastForDeletedItem(item.name);
      setEditItemId(null, true);
      getItems();
      setConfirmDeleteId(null, true);
    },
    onError: (unused, item) => {
      toastStore.addErrorToastForDeletedItem(item.name);
    }
  });

  const modelColumns: DataTableColumnProps<AlarmNotificationResponseModel>[] =
    useMemo(() => {
      const selectedColumns = nodeId ? ['name'] : ['name', 'nodeIds'];

      return _.reduce(
        NotificationModelsWithNodePicker,
        (columns, model) => {
          const keyString = getPathStringFromModelKeyFunc(model.key);

          if (!selectedColumns.includes(keyString)) {
            return columns;
          }

          return [
            ...columns,
            {
              label: model.label,
              dataKey: getPathStringFromModelKeyFunc(model.key),
              minWidth: 100,
              dataFormatter: (unused: unknown, item) => (
                <ModelDisplay model={model} rawValue={_.get(item, keyString)} />
              )
            }
          ];
        },
        [] as DataTableColumnProps<AlarmNotificationResponseModel>[]
      );
    }, [nodeId]);

  const columns = [
    ...modelColumns,
    ...standardColumns<AlarmNotificationResponseModel>({
      onDelete: (item) => setConfirmDeleteId(item.id),
      onEdit: (item) => setEditItemId(item.id)
    })
  ];

  const data = useMemo(() => {
    const _searchString = _.toLower(searchString);

    return _.isEmpty(_searchString)
      ? notifications
      : notifications.filter((notification) => {
          return (
            _.defaultTo(_.toLower(notification.name), '').search(
              _searchString
            ) !== -1 ||
            _.get(
              getNodeFromMap(nodeMap, _.head(notification.nodeIds)),
              'name',
              ''
            )
              .toLowerCase()
              .search(_searchString) !== -1
          );
        });
  }, [nodeMap, notifications, searchString]);

  const confirmDeleteItem = notifications.find(
    (item) => item.id === confirmDeleteId
  );

  return (
    <Fragment>
      <ConfirmDeleteDialog
        isOpen={confirmDeleteItem != null}
        onModalClose={() => setConfirmDeleteId(null)}
        isLoading={isDeletingItem}
        itemName={confirmDeleteItem?.name}
        onDelete={() => deleteItem(confirmDeleteItem)}
      />
      {!isLoadingItems && (
        <EditNotificationDialog
          nodeId={nodeId}
          isOpen={editItemId != null}
          notification={notifications.find((item) => item.id === editItemId)}
          isLoading={isCreatingItem}
          onModalClose={() => setEditItemId(null)}
          onAddInput={createItem}
          onDelete={(item) => setConfirmDeleteId(item.id)}
        />
      )}

      <DataTable<AlarmNotificationResponseModel>
        hasError={hasError}
        isLoading={isLoadingItems}
        data={data}
        columns={columns}
      />
    </Fragment>
  );
};

export default React.memo(NotificationTable);
