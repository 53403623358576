import React, { useState, useCallback, useEffect, useMemo } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import useFormState from 'ecto-common/lib/hooks/useFormState';

import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import { modelFormIsValid } from 'ecto-common/lib/ModelForm/validateForm';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import API from 'ecto-common/lib/API/API';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';

import {
  getHardwareWiredSettings,
  initialFormState,
  WiredInterface,
  WiredInterfaces
} from 'js/components/EnergyManagers/Models/HardwareSettingsModel';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import {
  AddOrUpdateIoTDeviceSettingsRequestModel,
  IoTDeviceResponseModel,
  IoTDeviceSettingsResponseModel,
  NetworkInterfaceType
} from 'ecto-common/lib/API/APIGen';
import WiredNetworkSection from './WiredNetworkSection';
import WirelessNetworkSection from './WirelessNetworkSection';
import { EnergyManagerModels } from 'ecto-common/lib/constants';

const EditIoTDeviceHardwareSettingsModal = ({
  iotDevice,
  isOpen,
  onModalClose
}: {
  iotDevice?: IoTDeviceResponseModel;
  isOpen?: boolean;
  onModalClose: () => void;
}) => {
  const iotDeviceId = iotDevice?.id;
  const [input, setInput] = useState<IoTDeviceSettingsResponseModel>({
    ioTDeviceId: undefined
  });

  const [hasError, setError] = useState(false);
  const wiredInterfaces: WiredInterface[] = useMemo(
    () =>
      iotDevice?.model === EnergyManagerModels.NPEX500
        ? [WiredInterfaces.ETH0, WiredInterfaces.ETH1]
        : [WiredInterfaces.ETH0],
    [iotDevice?.model]
  );

  const hardwareWiredSettings = useMemo(
    () => _.flatMap(wiredInterfaces, getHardwareWiredSettings),
    [wiredInterfaces]
  );

  const initialState = useMemo(
    () => initialFormState(wiredInterfaces),
    [wiredInterfaces]
  );

  const [formState, onChange] = useFormState<IoTDeviceSettingsResponseModel>(
    input,
    initialState
  );

  // Get settings return

  const [getIsLoading, getHardwareSettings] = usePromiseCall({
    promise: API.Admin.IoTDevices.getSettings,
    onSuccess: (responses) => {
      const response = _.head(responses);
      if (response?.desiredSettings) {
        setInput(response);
      } else {
        // Special case when backend returns 'Unknown' (it was previously set to null)
        if (
          response.desiredSettings?.defaultNetworkInterface ===
          NetworkInterfaceType.Unknown
        ) {
          delete response.desiredSettings.defaultNetworkInterface;
        }
        const _data = _.merge(response, initialFormState);
        setInput(_data);
      }
    },
    onError: () => {
      setError(true);
      toastStore.addErrorToast(T.admin.iotdevicedetails.error.failedtoupdate);
    }
  });

  const [saveIsLoading, saveHardwareSettings] = usePromiseCall({
    promise: API.Admin.IoTDevices.updateSettings,
    onSuccess: (response) => {
      setInput(_.head(response));
      toastStore.addSuccessToast(T.admin.iotdevicedetails.savesuccess);
      onModalClose();
    },
    onError: () => toastStore.addErrorToast(T.admin.iotdevicedetails.savefailed)
  });

  const isLoading = getIsLoading || saveIsLoading;

  useEffect(() => {
    if (isLoading) {
      setError(false);
    }
  }, [isLoading]);

  const save = useCallback(() => {
    const saveParams = {
      ioTDeviceId: iotDeviceId,
      ...formState?.desiredSettings
    };
    // Cast due to response model having optional fields
    saveHardwareSettings(
      saveParams as AddOrUpdateIoTDeviceSettingsRequestModel
    );
  }, [formState, iotDeviceId, saveHardwareSettings]);

  useEffect(() => {
    if (iotDeviceId && isOpen) {
      getHardwareSettings(iotDeviceId);
    } else if (!isOpen) {
      setInput((oldInput) => {
        if (oldInput.ioTDeviceId === undefined) {
          return oldInput;
        }
        return {
          ioTDeviceId: undefined
        };
      });
    }
  }, [getHardwareSettings, iotDeviceId, isOpen]);

  const hasErrors = useMemo(
    () => !modelFormIsValid(hardwareWiredSettings, formState),
    [formState, hardwareWiredSettings]
  );

  const canSave =
    !hasError &&
    !_.isEqual(formState?.desiredSettings, input?.desiredSettings) &&
    !hasErrors;

  const onUpdateInput = useCallback(
    (dataKey: string[], value: unknown) => {
      onChange({ dataKey, value });
    },
    [onChange]
  );

  return (
    <ActionModal
      title={T.admin.iotdevicedetails.tab.networksettings}
      isOpen={isOpen}
      onModalClose={onModalClose}
      headerIcon={Icons.Settings}
      actionText={T.admin.iotdevicedetails.updatenetworksettings}
      onConfirmClick={save}
      disableActionButton={!canSave || isLoading}
    >
      {!hasError && (
        <LoadingContainer isLoading={isLoading}>
          {wiredInterfaces.map((wiredInterface) => (
            <WiredNetworkSection
              key={wiredInterface}
              wiredInterface={wiredInterface}
              formState={formState}
              onUpdateInput={onUpdateInput}
            />
          ))}
          <WirelessNetworkSection
            formState={formState}
            onUpdateInput={onUpdateInput}
          />
        </LoadingContainer>
      )}

      {hasError && <ErrorNotice>{T.common.datatable.error}</ErrorNotice>}
    </ActionModal>
  );
};

export default EditIoTDeviceHardwareSettingsModal;
