import React, { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import _ from 'lodash';

import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import API from 'ecto-common/lib/API/API';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import useInterval from 'ecto-common/lib/hooks/useInterval';

import DeviceListFilter, {
  DeviceListFilterParams
} from 'js/components/EnergyManagers/DeviceListFilter';
import DeviceListTable from 'js/components/EnergyManagers/DeviceListTable';
import EnergyManagerDetailsModal from 'js/components/EnergyManagers/EnergyManagerDetailsModal';
import {
  IoTQueryTypes,
  IoTStatusQueryTypes
} from 'js/components/EnergyManagers/energyManagersUtil';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { useLocation } from 'react-router-dom';
import {
  IoTDevicesSummaryResponseModel,
  IoTDeviceViewResponseModel,
  IoTDeviceWithDeviceResponseModel
} from 'ecto-common/lib/API/APIGen';

const initialFilterParamState: DeviceListFilterParams = {
  searchPhrase: '',
  page: 0,
  filteredTags: [],
  queryType: IoTQueryTypes.UNPAIRED,
  statusQueryType: IoTStatusQueryTypes.ALL
};

type LocationSearchQueryType = {
  filteredTags?: string | string[];
  page?: string;
};

const getFilterParams = (locationSearch: LocationSearchQueryType) => {
  if (locationSearch.filteredTags) {
    return _.isArray(locationSearch?.filteredTags)
      ? locationSearch.filteredTags
      : [locationSearch.filteredTags];
  }

  return initialFilterParamState.filteredTags;
};

export const parseLocationSearch = (
  location: ReturnType<typeof useLocation>
): DeviceListFilterParams => {
  const parsedLocationSearch = queryString.parse(
    location?.search
  ) as LocationSearchQueryType;

  return {
    ...initialFilterParamState,
    ...parsedLocationSearch,
    filteredTags: getFilterParams(parsedLocationSearch),
    page: parsedLocationSearch?.page
      ? Number(parsedLocationSearch?.page)
      : initialFilterParamState.page
  };
};

const DELAY = 10000;

interface DeviceListProps {
  onSearch?(newParams: Partial<DeviceListFilterParams>): void;
  useAsSelector?: boolean;
  onDeviceSelected?(newDevice: IoTDeviceViewResponseModel): void;
  pageSize?: number;
  filterParams: DeviceListFilterParams;
}

/**
 * Shows a list of all IoT devices in the system
 * @param onSearch
 * @param onDeviceSelected
 * @param useAsSelector
 * @param pageSize
 * @param filterParams
 * @constructor
 */
const DeviceList = ({
  onSearch,
  onDeviceSelected,
  useAsSelector = false,
  pageSize = 15,
  filterParams = initialFilterParamState
}: DeviceListProps) => {
  const [tags, setTags] = useState<string[]>([]);
  const [devices, setDevices] = useState<IoTDeviceWithDeviceResponseModel[]>(
    []
  );
  const [summary, setSummary] = useState<IoTDevicesSummaryResponseModel>(null);
  const [hasError, setHasError] = useState(false);

  const [deviceDetailId, setDeviceDetailId] = useSearchParamState(
    'edit-device-id',
    null
  );

  // Store devices in cache, otherwise a a refresh might make the
  // detail device disappear from devices variable
  const [deviceCache, setDeviceCache] = useState<
    Record<string, IoTDeviceWithDeviceResponseModel>
  >({});

  const detailDevice = deviceCache[deviceDetailId];

  const [searchIsLoading, onSearchEnergyManagers] = usePromiseCall({
    promise: API.Admin.IoTDevices.search,
    onSuccess: (response) => {
      setDeviceCache((oldCache) => {
        const newCache = { ...oldCache };
        for (const device of response?.results ?? []) {
          newCache[device.ioTDevice.id] = device;
        }
        return newCache;
      });

      setDevices(response?.results);
      setSummary(response?.summary);
    },
    onError: () => {
      setHasError(true);
    }
  });

  const [getTagsIsLoading, getTags] = usePromiseCall({
    promise: API.Admin.IoTDevices.getDeviceTags,
    onSuccess: (response) => {
      setTags(sortByLocaleCompare(response));
    },
    onError: () => {
      setHasError(true);
    }
  });

  const isLoading = searchIsLoading || getTagsIsLoading;

  const doSearch = useCallback(() => {
    const { searchPhrase, queryType, statusQueryType, filteredTags, page } =
      filterParams;

    setHasError(false);
    onSearchEnergyManagers(
      searchPhrase,
      queryType,
      statusQueryType,
      filteredTags,
      pageSize,
      page
    );
  }, [filterParams, onSearchEnergyManagers, pageSize]);

  useEffect(() => {
    getTags();
  }, [getTags]);

  useInterval(doSearch, DELAY, true);

  const _onDeviceSelected = useCallback(
    (device: IoTDeviceWithDeviceResponseModel) => {
      if (onDeviceSelected != null) {
        onDeviceSelected(device);
      } else {
        setDeviceDetailId(device.ioTDevice?.id);
      }
    },
    [onDeviceSelected, setDeviceDetailId]
  );

  const _onModalClose = useCallback(() => {
    setDeviceDetailId(null);
  }, [setDeviceDetailId]);

  const updatesFilterParams = useCallback(
    (newValue: Partial<DeviceListFilterParams>) => {
      if (onSearch != null) {
        const newState = { ...filterParams, page: 0, ...newValue };

        onSearch(newState);
      }
    },
    [filterParams, onSearch]
  );

  const onSearchPhraseChange = useCallback(
    (searchPhrase: string) => {
      updatesFilterParams({ searchPhrase });
    },
    [updatesFilterParams]
  );

  const onQueryTypeChange = useCallback(
    (queryType: IoTQueryTypes) => {
      updatesFilterParams({ queryType });
    },
    [updatesFilterParams]
  );

  const onStatusQueryTypeChange = useCallback(
    (statusQueryType: IoTStatusQueryTypes) => {
      updatesFilterParams({ statusQueryType });
    },
    [updatesFilterParams]
  );

  const onFilteredTagsChange = useCallback(
    (filteredTags: string[]) => {
      updatesFilterParams({ filteredTags });
    },
    [updatesFilterParams]
  );

  const onPageChange = useCallback(
    (page: number) => {
      updatesFilterParams({ page });
    },
    [updatesFilterParams]
  );

  return (
    <>
      <DeviceListFilter
        filterParams={filterParams}
        tags={tags}
        onSearchPhraseChange={onSearchPhraseChange}
        onQueryTypeChange={onQueryTypeChange}
        onStatusQueryTypeChange={onStatusQueryTypeChange}
        onFilteredTagsChange={onFilteredTagsChange}
        useAsSelector={useAsSelector}
      />

      <DeviceListTable
        filterParams={filterParams}
        devices={devices}
        summary={summary}
        onDeviceSelected={_onDeviceSelected}
        useAsSelector={useAsSelector}
        pageSize={pageSize}
        onPageChange={onPageChange}
        isLoading={isLoading}
        hasError={hasError}
      />

      <EnergyManagerDetailsModal
        detailDevice={detailDevice}
        isOpen={detailDevice != null}
        onModalClose={_onModalClose}
      />
    </>
  );
};

export default React.memo(DeviceList);
