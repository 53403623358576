import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import SignalTypeTreeView from 'ecto-common/lib/SignalTypePicker/SignalTypeTreeView';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import AddButton from 'ecto-common/lib/Button/AddButton';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import Icons from 'ecto-common/lib/Icons/Icons';
import { patchSignalTypeFolders } from 'ecto-common/lib/actions/setSignalTypeFolders';
import ModelFormDialog from 'ecto-common/lib/ModelForm/ModelFormDialog';
import API from 'ecto-common/lib/API/API';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { patchSignalTypes } from 'ecto-common/lib/actions/setSignalTypes';
import Button from 'ecto-common/lib/Button/Button';
import HttpStatus from 'ecto-common/lib/utils/HttpStatus';
import HelpPaths from 'ecto-common/help/tocKeys';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import {
  AddOrUpdateSignalTypeFolderRequestModel,
  AddOrUpdateSignalTypeRequestModel,
  SignalTypeFolderResponseModel,
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';

export const isUserType = (node: SignalTypeResponseModel) => {
  return !node.isSystem;
};

const NAME_MAX_LENGTH = 100;
const DESCRIPTION_MAX_LENGTH = 500;

const getTypeModels = (
  units: UnitResponseModel[]
): ModelDefinition<SignalTypeResponseModel>[] => [
  {
    key: (input) => input.name,
    label: T.admin.signaltypes.form.name,
    modelType: ModelType.TEXT,
    hasError: (value: string) =>
      _.isEmpty(value) || value?.length >= NAME_MAX_LENGTH,
    enabled: isUserType,
    autoFocus: true
  },
  {
    key: (input) => input.description,
    label: T.admin.signaltypes.typeform.description,
    enabled: isUserType,
    modelType: ModelType.TEXT,
    hasError: (value: string) => value?.length >= DESCRIPTION_MAX_LENGTH
  },
  {
    key: (input) => input.signalTypeFolderId,
    label: T.admin.signaltypes.typeform.folder,
    modelType: ModelType.SIGNAL_TYPE,
    enabled: isUserType,
    selectFolder: true,
    hasError: _.isEmpty
  },
  {
    key: (input) => input.unitId,
    label: T.admin.signaltypes.typeform.unit,
    placeholder: T.signaltypes.selectunit,
    modelType: ModelType.OPTIONS,
    enabled: isUserType,
    options: units.map((unit) => ({
      label: unit.name,
      value: unit.id
    })),
    hasError: _.isEmpty
  }
];

const folderModels: ModelDefinition<SignalTypeFolderResponseModel>[] = [
  {
    key: (input) => input.name,
    label: T.admin.signaltypes.form.name,
    modelType: ModelType.TEXT,
    hasError: _.isEmpty,
    enabled: isUserType,
    autoFocus: true
  },
  {
    key: (input) => input.parentId,
    label: T.admin.signaltypes.folderform.folder,
    modelType: ModelType.SIGNAL_TYPE,
    enabled: isUserType,
    selectFolder: true
  }
];

const SignalTypes = () => {
  const enums = useAdminSelector((state) => state.general.enums);
  const signalTypesMap = useAdminSelector(
    (state) => state.general.signalTypesMap
  );
  const signalTypeFoldersMap = useAdminSelector(
    (state) => state.general.signalTypeFoldersMap
  );

  const [searchFilter, setSearchFilter] = useState<string>(null);

  const [editFolder, setEditFolder] =
    useState<SignalTypeFolderResponseModel>(null);
  const clearEditFolder = useCallback(() => setEditFolder(null), []);
  const [editType, setEditType] = useState<SignalTypeResponseModel>(null);
  const clearEditType = useCallback(() => setEditType(null), []);
  const [selectedNodes, setSelectedNodes] = useState<Record<string, boolean>>(
    {}
  );

  const selectedNodeKey = _.head(_.keys(selectedNodes));
  const selectedFolder = _.get(signalTypeFoldersMap, selectedNodeKey);
  const selectedType = _.get(signalTypesMap, selectedNodeKey);
  const dispatch = useAdminDispatch();

  let parentId: string = null;

  if (selectedFolder != null) {
    parentId = selectedFolder.id;
  } else if (selectedType) {
    parentId = selectedType.signalTypeFolderId;
  }

  const editNode = useCallback(() => {
    setEditType(selectedType);
    setEditFolder(selectedFolder);
  }, [selectedType, selectedFolder]);

  const addFolder = useCallback(() => {
    setEditFolder({
      id: undefined,
      isSystem: false,
      name: T.admin.signaltypes.newfolder,
      description: null,
      parentId
    });
  }, [parentId]);

  const addType = useCallback(() => {
    setEditType({
      id: undefined,
      isSystem: false,
      unitId: undefined,
      name: T.admin.signaltypes.newtype,
      description: null,
      signalTypeFolderId: parentId
    });
  }, [parentId]);

  const [isSavingFolder, saveFolder] = usePromiseCall({
    promise: API.Admin.SignalTypeFolders.saveSignalTypeFolders,
    onSuccess: (
      _emptyRes,
      editedItems: AddOrUpdateSignalTypeFolderRequestModel[]
    ) => {
      const editedItem = _.head(editedItems);
      dispatch(
        patchSignalTypeFolders({
          editedItems,
          deletedItems: []
        })
      );
      clearEditFolder();
      setSelectedNodes({
        [editedItem.id]: true
      });
      toastStore.addSuccessToastForUpdatedItem(
        editedItem.name,
        editFolder.id == null
      );
    },
    onError: (_err, editedItems: AddOrUpdateSignalTypeFolderRequestModel[]) => {
      const editedItem = _.head(editedItems);
      toastStore.addErrorToastForUpdatedItem(
        editedItem.name,
        editFolder.id == null
      );
    }
  });

  const [isSavingType, saveType] = usePromiseCall({
    promise: API.Admin.SignalTypes.saveSignalTypes,
    onSuccess: (_emptyRes, editedItems) => {
      const editedItem = _.head(editedItems);
      dispatch(
        patchSignalTypes({
          editedItems,
          deletedItems: []
        })
      );
      clearEditType();
      setSelectedNodes({
        [editedItem.id]: true
      });
      toastStore.addSuccessToastForUpdatedItem(
        editedItem.name,
        editType.id == null
      );
    },
    onError: (e, editedItems: AddOrUpdateSignalTypeRequestModel[]) => {
      if (e.response?.status === HttpStatus.CONFLICT) {
        toastStore.addErrorToast(T.admin.signaltypes.typeform.conflicterror);
      } else {
        const editedItem = _.head(editedItems);
        toastStore.addErrorToastForUpdatedItem(
          editedItem.name,
          editType.id == null
        );
      }
    }
  });

  const typeModels = useMemo(() => getTypeModels(enums.units), [enums.units]);
  useEffect(() => {
    document.title = T.admin.tabs.signaltypes;
  }, []);

  const toolbarItems = (
    <>
      <ToolbarFlexibleSpace />
      <ToolbarItem>
        <Button
          onClick={editNode}
          disabled={
            (!selectedType && !selectedFolder) ||
            selectedType?.isSystem ||
            selectedFolder?.isSystem
          }
        >
          <Icons.Edit />
          {T.admin.signaltypes.editbuttontext}
        </Button>
      </ToolbarItem>
      <ToolbarItem>
        <AddButton onClick={addType}>
          {T.admin.signaltypes.addbuttontext}
        </AddButton>
      </ToolbarItem>
      <ToolbarItem>
        <AddButton onClick={addFolder}>
          {T.admin.signaltypes.addfolderbuttontext}
        </AddButton>
      </ToolbarItem>
    </>
  );
  return (
    <ToolbarContentPage
      title={T.admin.tabs.signaltypes}
      showLocationPicker={false}
      searchString={searchFilter}
      onSearchInput={setSearchFilter}
      toolbarItems={toolbarItems}
      wrapContent={false}
      dockToolbar
      helpPath={HelpPaths.docs.admin.templates.signal_types}
    >
      <SignalTypeTreeView
        searchFilter={searchFilter}
        selectFolder
        selectedNodes={selectedNodes}
        setSelectedNodes={setSelectedNodes}
        showIconForLockedTypes
      />
      <ModelFormDialog
        onModalClose={clearEditFolder}
        input={editFolder}
        editTitle={T.admin.signaltypes.editfoldertitle}
        addTitle={T.admin.signaltypes.addfoldertitle}
        isSavingInput={isSavingFolder}
        saveInput={saveFolder}
        models={folderModels}
      />
      <ModelFormDialog
        onModalClose={clearEditType}
        input={editType}
        editTitle={T.admin.signaltypes.edittypetitle}
        addTitle={T.admin.signaltypes.addtypetitle}
        isSavingInput={isSavingType}
        saveInput={saveType}
        models={typeModels}
      />
    </ToolbarContentPage>
  );
};

export default React.memo(SignalTypes);
