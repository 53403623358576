import React, { useContext, useEffect, useMemo, useState } from 'react';

import T from 'ecto-common/lib/lang/Language';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import {
  getEquipmentNode,
  getNodeFromMap
} from 'ecto-common/lib/utils/locationUtils';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';

import styles from './SignalMappingList.module.css';
import DeleteSignalMappingModal from 'js/components/SignalMapping/DeleteSignalMappingModal';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import API, {
  CancellablePromiseCallback,
  cancellablePromiseSequence
} from 'ecto-common/lib/API/API';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import _ from 'lodash';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import { getLocationRoute } from 'js/utils/routeConstants';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import {
  SignalWithProvider,
  SingleGridNode
} from 'ecto-common/lib/types/EctoCommonTypes';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import {
  DestinationConfigSignalResponseModel,
  FullSignalProviderResponseModel,
  MappingResponseModel,
  SourceConfigSignalResponseModel
} from 'ecto-common/lib/API/APIGen';

const getMapsPromise = (
  contextSettings: ApiContextSettings,
  nodeId: string
) => {
  const mapsPromise =
    nodeId != null ? API.Admin.Maps.getMapsForNodeId : API.Admin.Maps.getMaps;

  return cancellablePromiseSequence<
    [
      mappings: MappingResponseModel[],
      providers: FullSignalProviderResponseModel[]
    ]
  >((withNextPromise: CancellablePromiseCallback) => {
    return withNextPromise(mapsPromise(contextSettings, nodeId)).then(
      (result: MappingResponseModel[]) => {
        const sourceSignals = _.flatMap(result, 'sourceConfig.configSignals');
        const destSignals = _.flatMap(
          result,
          'destinationConfig.configSignals'
        );
        const signalIds = _.uniq(
          _.map([...sourceSignals, ...destSignals], 'signalId')
        );

        if (signalIds.length === 0) {
          return Promise.all([Promise.resolve(result), Promise.resolve([])]);
        }

        return Promise.all([
          Promise.resolve(result),
          withNextPromise(
            API.Admin.Signals.getProvidersBySignalIds(
              contextSettings,
              signalIds
            )
          )
        ]);
      }
    );
  });
};

interface SignalMappingListProps {
  selectedLocation?: SingleGridNode;
  onItemSelected(mapping: MappingResponseModel): void;
  reloadTrigger?: number;
}

const SignalMappingList = ({
  selectedLocation,
  onItemSelected,
  reloadTrigger: externalReloadTrigger
}: SignalMappingListProps) => {
  const [reloadTrigger, triggerReload] = useReloadTrigger(
    externalReloadTrigger
  );
  const nodeId = useAdminSelector((state) => state.general.nodeId);
  const [deleteItem, setDeleteItem] = useState<MappingResponseModel>(null);
  const [signalMappings, setSignalMappings] = useState<MappingResponseModel[]>(
    []
  );
  const [signalData, setSignalData] = useState<
    Record<string, SignalWithProvider>
  >({});
  const [hasError, setHasError] = useState(false);
  const nodeMap = useAdminSelector((state) => state.general.nodeMap);
  const equipmentMap = useAdminSelector((state) => state.general.equipmentMap);
  const { tenantId } = useContext(TenantContext);

  const [isLoading, getSignalMappings, cancelGetSignalMappings] =
    usePromiseCall({
      promise: getMapsPromise,
      onSuccess: ([_signalMappings, _providers]) => {
        const signalsWithProviders: Record<string, SignalWithProvider> = _(
          _providers
        )
          .flatMap((provider) =>
            _.map(provider.signals, (signal) => ({ ...signal, provider }))
          )
          .keyBy('signalId')
          .value();

        setSignalData(signalsWithProviders);
        setHasError(false);
        setSignalMappings(sortByLocaleCompare(_signalMappings, 'name'));
      },
      onError: () => {
        setHasError(true);
        setSignalMappings([]);
      }
    });

  useEffect(() => {
    if (selectedLocation.nodeId.startsWith(ROOT_NODE_ID)) {
      setSignalMappings([]);
    } else {
      getSignalMappings(selectedLocation.nodeId);
    }

    return () => {
      cancelGetSignalMappings();
    };
  }, [
    selectedLocation.nodeId,
    getSignalMappings,
    reloadTrigger,
    cancelGetSignalMappings
  ]);

  const columns: DataTableColumnProps<MappingResponseModel>[] = useMemo(() => {
    const signalFormatter = (
      signals: (
        | SourceConfigSignalResponseModel
        | DestinationConfigSignalResponseModel
      )[],
      _unused: MappingResponseModel,
      signalIdx: number
    ) => {
      const items = _.map(signals, (signal) => {
        const signalInfo = signalData[signal.signalId];
        let title = null;
        let nodeNameSuffix = null;
        let signalNodeId = null;

        if (signalInfo != null) {
          title =
            signalInfo.name + ' - ' + signalInfo.provider.signalProviderName;
        }

        if (signalInfo?.provider?.nodeIds.length === 1) {
          const id = _.head(signalInfo.provider.nodeIds);
          const eqNode = getEquipmentNode(id, nodeMap, equipmentMap);
          const node = eqNode ?? getNodeFromMap(nodeMap, id);

          if (node != null && node.nodeId !== nodeId) {
            nodeNameSuffix = node.name;
            signalNodeId = node.nodeId;
          }
        }

        if (nodeNameSuffix != null) {
          return (
            <TableColumn
              key={signalIdx}
              title={title}
              subtitle={nodeNameSuffix}
              subtitleLink={getLocationRoute(
                tenantId,
                signalNodeId,
                'signalmapping'
              )}
            />
          );
        }

        return title;
      });

      return <>{items}</>;
    };

    return [
      {
        label: T.admin.signalmapping.common.name,
        dataKey: 'name',
        minWidth: 120
      },
      {
        label: T.admin.signalmapping.source.title,
        dataKey: 'sourceConfig.configSignals',
        dataFormatter: signalFormatter
      },
      {
        label: T.admin.signalmapping.destination.title,
        dataKey: 'destinationConfig.configSignals',
        dataFormatter: signalFormatter
      },
      ...standardColumns({
        onEdit: onItemSelected,
        onDelete: setDeleteItem
      })
    ];
  }, [
    onItemSelected,
    setDeleteItem,
    signalData,
    nodeMap,
    equipmentMap,
    nodeId,
    tenantId
  ]);

  return (
    <>
      <DataTable<MappingResponseModel>
        className={styles.listTable}
        isLoading={isLoading}
        data={signalMappings}
        hasError={hasError}
        columns={columns}
        onClickRow={onItemSelected}
        noDataText={T.admin.signalmapping.nodata}
        numRowsToShowPerScroll={100}
      />
      <DeleteSignalMappingModal
        deleteItem={deleteItem}
        onSuccess={triggerReload}
        onModalClose={() => setDeleteItem(null)}
      />
    </>
  );
};

export default React.memo(SignalMappingList);
