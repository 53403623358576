import React, { useCallback, useMemo, useContext } from 'react';
import _ from 'lodash';

import {
  DEFAULT_LAT,
  DEFAULT_LNG,
  NODE_TYPE_BUILDING,
  ROOT_NODE_ID
} from 'ecto-common/lib/constants';

import Heading from 'ecto-common/lib/Heading/Heading';
import {
  getNodeFromMap,
  localizeGridName,
  localizeLocationType
} from 'ecto-common/lib/utils/locationUtils';
import Button from 'ecto-common/lib/Button/Button';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import CreatableSelect from 'ecto-common/lib/Select/CreatableSelect';
import KeyValueInternalSelectableInput from 'ecto-common/lib/KeyValueInput/Internal/KeyValueInternalSelectableInput';
import DraggableMarkerMap from 'ecto-common/lib/Map/DraggableMarkerMap';

import styles from 'js/components/EditLocation/LocationForm.module.css';
import pageStyles from 'js/components/PageStyle.module.css';
import { BuildingStatusText } from 'ecto-common/lib/utils/buildingStatusUtil';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import {
  BuildingInfoResponseModel,
  BuildingStatus,
  GridType,
  NodeType
} from 'ecto-common/lib/API/APIGen';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { enumValues } from 'ecto-common/lib/utils/typescriptUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { ResourceType } from 'ecto-common/lib/constants/index';
import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';
import { useSyncExternalStore } from 'use-sync-external-store/shim';

const createInfoItem = (title: string, content: string | number) => ({
  title,
  content
});

export type LocationFormData = {
  name: string;
  street: string;
  nodeType: NodeType;
  latitude: number;
  longitude: number;
  buildingInfo: BuildingInfoResponseModel;
  tags: string[];
  grids: GridType[];
};

interface LocationFormProps {
  formData: LocationFormData;
  onFormDataChanged(newData: Partial<LocationFormData>): void;
  location?: SingleGridNode;
  onAddNewBuilding(): void;
  onAddNewSite(): void;
  onDeleteLocation(): void;
  onEditDashboards(): void;
  onEditProcessMaps(): void;
  onEditMeteorology(): void;
  onEditNotifications(): void;
  onEditParents(): void;
  onEditIntegrations(): void;
  onEditTools(): void;
  isVirtualRootNode: boolean;
}

type LocationButtonType = {
  onClick: () => void;
  text: string;
  icon: React.ReactNode;
  isGrey?: boolean;
};

const LocationForm = ({
  formData,
  onFormDataChanged,
  location,
  onAddNewBuilding,
  onAddNewSite,
  onDeleteLocation,
  onEditDashboards,
  onEditProcessMaps,
  onEditMeteorology,
  onEditNotifications,
  onEditParents,
  onEditTools,
  onEditIntegrations,
  isVirtualRootNode
}: LocationFormProps) => {
  const nodeTags = useAdminSelector((state) => state.general.nodeTags);
  const nodeMap = useAdminSelector((state) => state.general.nodeMap);
  const { tenantResources } = useContext(TenantContext);
  const featureFlagState = useSyncExternalStore(
    featureFlagStore.subscribe,
    featureFlagStore.getSnapshot
  );

  const onUpdateName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFormDataChanged({ name: event.target.value });
    },
    [onFormDataChanged]
  );

  const onUpdateDistrictHeatingFacilityId = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const buildingInfo = _.merge(_.cloneDeep(formData.buildingInfo), {
        districtHeatingFacilityId: event.target.value
      });
      onFormDataChanged({ buildingInfo });
    },
    [formData.buildingInfo, onFormDataChanged]
  );

  const onBuildingStatusChanged = useCallback(
    ({ value }: GenericSelectOption<BuildingStatus>) => {
      const buildingInfo = _.merge(_.cloneDeep(formData.buildingInfo), {
        buildingStatus: value
      });
      onFormDataChanged({ buildingInfo });
    },
    [formData.buildingInfo, onFormDataChanged]
  );

  const updateStreet = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFormDataChanged({ street: event.target.value });
    },
    [onFormDataChanged]
  );

  const onTagsChanged = useCallback(
    (newTags: GenericSelectOption<string>[]) => {
      const tags = _.map(newTags, 'value');
      onFormDataChanged({ tags });
    },
    [onFormDataChanged]
  );

  const onCoordinateChanged = useCallback(
    (latitude: number, longitude: number) => {
      onFormDataChanged({ latitude, longitude });
    },
    [onFormDataChanged]
  );

  const parentNames = _.compact(
    _.map(location.parentIds, (id) => getNodeFromMap(nodeMap, id)?.name)
  );

  const isRootNode = location.nodeId.startsWith(ROOT_NODE_ID);

  const isBuilding = location.nodeType === NODE_TYPE_BUILDING;

  const createButtonRow = useCallback(
    (button: LocationButtonType, buttonIndex: number) => {
      const { onClick, text, icon, isGrey } = button;

      return (
        <tr key={'row' + buttonIndex}>
          <td colSpan={2}>
            {(isGrey === true && (
              <GreyButton compact key={buttonIndex} onClick={onClick}>
                {icon}
                {text}
              </GreyButton>
            )) || (
              <Button compact key={buttonIndex} onClick={onClick}>
                {icon}
                {text}
              </Button>
            )}
          </td>
        </tr>
      );
    },
    []
  );

  const sharedButtons: LocationButtonType[] = useMemo(
    () =>
      _.compact([
        featureFlagState.integrations && {
          onClick: onEditIntegrations,
          text: T.admin.integrations.managepoints,
          icon: <Icons.Data />
        },
        hasAccessToResource(ResourceType.USER_MANAGEMENT, tenantResources) && {
          onClick: onEditNotifications,
          text: T.admin.editlocation.editnotifications.button,
          icon: <Icons.Notification />
        },
        {
          onClick: onEditParents,
          text: T.admin.editlocation.editparents.button,
          icon: <Icons.Parents />
        },
        {
          onClick: onEditMeteorology,
          text: T.admin.editlocation.editmeteorology,
          icon: <Icons.Weather />
        },
        {
          onClick: onEditDashboards,
          text: T.admin.dashboards.configuredashboardcollection,
          icon: <Icons.Dashboard />
        },
        {
          onClick: onEditProcessMaps,
          text: T.admin.editlocation.editprocessmap,
          icon: <Icons.Settings />
        },
        {
          onClick: onDeleteLocation,
          text: isBuilding
            ? T.admin.editbuilding.deletelocation.title
            : T.admin.editsite.deletelocation.title,
          icon: <Icons.Delete />,
          isGrey: true
        }
      ]),
    [
      featureFlagState.integrations,
      onEditIntegrations,
      onEditNotifications,
      onEditParents,
      tenantResources,
      onEditMeteorology,
      onEditDashboards,
      onEditProcessMaps,
      onDeleteLocation,
      isBuilding
    ]
  );

  const buildingButtons: LocationButtonType[] = useMemo(
    () => [
      {
        onClick: onEditTools,
        text: T.admin.editlocation.edittools,
        icon: <Icons.Tool />
      }
    ],
    [onEditTools]
  );

  const siteButtons: LocationButtonType[] = useMemo(
    () =>
      _.compact([
        !isVirtualRootNode && {
          onClick: onAddNewSite,
          text: T.admin.editlocation.addnewsite,
          icon: <Icons.Site />
        },
        !isVirtualRootNode && {
          onClick: onAddNewBuilding,
          text: T.admin.editlocation.addnewbuilding,
          icon: <Icons.Building />
        }
      ]),
    [isVirtualRootNode, onAddNewBuilding, onAddNewSite]
  );

  const createOption = (tag: string) => ({
    value: tag,
    label: tag
  });

  const tagOptions: GenericSelectOption<string>[] = useMemo(
    () => nodeTags.map(createOption),
    [nodeTags]
  );

  const tagValue: GenericSelectOption<string>[] = useMemo(
    () => _.map(formData.tags, createOption),
    [formData.tags]
  );

  const gridOptions: GenericSelectOption<GridType>[] = useMemo(() => {
    return _.map(enumValues(GridType), (key) => ({
      value: key,
      label: localizeGridName(key)
    }));
  }, []);

  const inputItems = useMemo(
    () =>
      _.compact([
        {
          title: T.admin.editlocation.fields.name,
          content: (
            <TextInput
              autoComplete="off"
              spellCheck="false"
              wrapperClassName={styles.inputItem}
              autoFocus
              disabled={isRootNode}
              placeholder={T.admin.editlocation.placeholder.name}
              value={formData.name}
              onChange={onUpdateName}
            />
          )
        },
        !isRootNode && {
          title: T.admin.editlocation.fields.street,
          content: (
            <TextInput
              autoComplete="off"
              spellCheck="false"
              wrapperClassName={styles.inputItem}
              placeholder={T.admin.editlocation.placeholder.street}
              value={formData.street}
              onChange={updateStreet}
            />
          )
        },
        {
          title: T.admin.editlocation.fields.grids,
          content: (
            <Select<GenericSelectOption<GridType>, true>
              isMulti
              value={gridOptions.filter((x) =>
                formData.grids.includes(x.value)
              )}
              options={gridOptions}
              onChange={(newGrids: GenericSelectOption<GridType>[]) =>
                onFormDataChanged({ grids: _.map(newGrids, 'value') })
              }
            />
          )
        },
        !isRootNode && {
          title: T.admin.editlocation.tags,
          content: (
            <CreatableSelect<true>
              isMulti
              value={tagValue}
              options={tagOptions}
              onChange={onTagsChanged}
              showOptionWhenEmpty={false}
            />
          )
        },
        _.get(location, 'buildingInfo') && {
          title: T.admin.editlocation.fields.districtheatingfacilityid,
          content: (
            <TextInput
              key={'facilityid_' + location.nodeId}
              autoComplete="off"
              spellCheck="false"
              maxLength={20}
              wrapperClassName={styles.inputItem}
              disabled={isRootNode}
              placeholder={
                T.admin.editlocation.placeholder.districtheatingfacilityid
              }
              value={_.get(location, 'buildingInfo.districtHeatingFacilityId')}
              onChange={onUpdateDistrictHeatingFacilityId}
            />
          )
        },
        isBuilding &&
          formData.buildingInfo && {
            title: T.admin.editlocation.fields.buildingstatus,
            content: (
              <KeyValueInternalSelectableInput
                value={{
                  value: formData.buildingInfo.buildingStatus,
                  label:
                    BuildingStatusText[formData.buildingInfo.buildingStatus]
                }}
                options={_.map(
                  enumValues(BuildingStatus).filter(
                    (value) => value !== BuildingStatus.Unknown
                  ),
                  (key) => ({ value: key, label: BuildingStatusText[key] })
                )}
                onChange={onBuildingStatusChanged}
              />
            )
          }
      ]),
    [
      isRootNode,
      formData.name,
      formData.street,
      formData.buildingInfo,
      formData.grids,
      onUpdateName,
      updateStreet,
      gridOptions,
      tagValue,
      tagOptions,
      onTagsChanged,
      location,
      onUpdateDistrictHeatingFacilityId,
      isBuilding,
      onBuildingStatusChanged,
      onFormDataChanged
    ]
  );

  const infoItems = useMemo(
    () =>
      location &&
      _.compact([
        createInfoItem(
          T.admin.editlocation.fields.type,
          localizeLocationType(location.nodeType)
        ),
        createInfoItem(
          T.admin.editlocation.fields.grids,
          location.grids.map(localizeGridName).join(', ')
        ),
        createInfoItem(
          T.admin.editlocation.fields.parents,
          parentNames.join(', ')
        ),
        createInfoItem(
          T.admin.editlocation.fields.childlocations,
          location.children.length
        ),
        createInfoItem(
          T.admin.editlocation.fields.activealarms,
          location.numberOfActiveAlarms
        ),
        createInfoItem(
          T.admin.editlocation.fields.equipment,
          _.get(location, 'equipments', []).length
        ),
        createInfoItem(
          T.admin.editlocation.fields.powercontrols,
          _.get(location, 'powerControls', []).length
        ),
        createInfoItem(
          T.admin.editlocation.fields.linearoptimisations,
          _.get(location, 'linearOptimisations', []).length
        )
      ]),
    [location, parentNames]
  );

  const center = useMemo(
    () => ({
      lat: formData.latitude ?? DEFAULT_LAT,
      lng: formData.longitude ?? DEFAULT_LNG
    }),
    [formData.latitude, formData.longitude]
  );

  return (
    <form autoComplete="off">
      <div className={styles.wrapper}>
        <div className={styles.mapArea}>
          <div className={styles.map}>
            <DraggableMarkerMap
              disableMarker={isRootNode}
              initialLatitude={center.lat}
              initialLongitude={center.lng}
              onCoordinateChanged={onCoordinateChanged}
            />
          </div>
        </div>

        <div className={styles.sidebarArea}>
          <Heading level={4}>{T.admin.editlocation.details}</Heading>

          <table className={styles.infoTable}>
            <tbody>
              {!isVirtualRootNode &&
                _.map(inputItems, (inputItem) => {
                  return (
                    <tr key={inputItem.title}>
                      <td className={styles.infoColumn}>
                        <label>{inputItem.title}</label>
                      </td>

                      <td>{inputItem.content}</td>
                    </tr>
                  );
                })}

              {!_.isEmpty(infoItems) && (
                <>
                  {!isVirtualRootNode &&
                    _.map(infoItems, (infoItem) => {
                      return (
                        <tr key={infoItem.title}>
                          <td className={pageStyles.minWidthColumn}>
                            <label>{infoItem.title}</label>
                          </td>
                          <td>{infoItem.content}</td>
                        </tr>
                      );
                    })}

                  <tr>
                    <td colSpan={2} />
                  </tr>

                  {isBuilding && _.map(buildingButtons, createButtonRow)}

                  {!isBuilding && _.map(siteButtons, createButtonRow)}

                  {!isRootNode && _.map(sharedButtons, createButtonRow)}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </form>
  );
};

export default LocationForm;
