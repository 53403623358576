import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import AddButton from 'ecto-common/lib/Button/AddButton';
import Icons from 'ecto-common/lib/Icons/Icons';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';

import DashboardsPageList from 'js/components/Dashboards/DashboardsPageList';
import styles from './DashboardCollectionSelectionListModal.module.css';
import { DashboardResponseModel } from 'ecto-common/lib/API/APIGen';

interface DashboardCollectionSelectionListModalProps {
  dashboards?: DashboardResponseModel[];
  onConfirm?(selectedItems: DashboardResponseModel[]): void;
}

const DashboardCollectionSelectionListModal = ({
  dashboards,
  onConfirm
}: DashboardCollectionSelectionListModalProps) => {
  const [isOpen, showDialog, hideDialog] = useSimpleDialogState();
  const [selectedItems, setSelectedItems] = useState<DashboardResponseModel[]>(
    dashboards ?? []
  );

  useEffect(() => {
    setSelectedItems(dashboards);
  }, [dashboards]);

  const _onConfirm = useCallback(() => {
    onConfirm(selectedItems);
    hideDialog();
  }, [onConfirm, selectedItems, hideDialog]);

  const onClickRow = useCallback((dashboard: DashboardResponseModel) => {
    setSelectedItems((prevState) =>
      _.xorBy(prevState, [dashboard], 'dashboardId')
    );
  }, []);

  return (
    <>
      <AddButton className={styles.button} onClick={showDialog}>
        {T.admin.dashboardcollection.selectdashboards}
      </AddButton>

      <ActionModal
        title={T.admin.dashboardcollection.selectdashboards}
        actionText={T.common.done}
        onModalClose={hideDialog}
        isOpen={isOpen}
        onConfirmClick={_onConfirm}
        headerIcon={Icons.Edit}
      >
        <DashboardsPageList
          pageSize={10}
          isMultipleSelect
          selectedDashboards={selectedItems}
          onSelectionChange={setSelectedItems}
          onClickRow={onClickRow}
        />
      </ActionModal>
    </>
  );
};

export default DashboardCollectionSelectionListModal;
