import React, { useCallback, useState, useEffect } from 'react';

import AddButton from 'ecto-common/lib/Button/AddButton';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import T from 'ecto-common/lib/lang/Language';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import useDialogState, {
  useSimpleDialogState
} from 'ecto-common/lib/hooks/useDialogState';

import DashboardsCollectionsPageList from 'js/components/DashboardCollections/DashboardCollectionsPageList';
import DashboardCollectionFormModal from 'js/components/DashboardCollections/DashboardCollectionFormModal';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import Button from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';
import SelectDefaultDashboardCollectionDialog from 'js/components/DashboardCollections/RelationModals/SelectDefaultDashboardCollectionDialog';
import { DashboardCollectionResponseModel } from 'ecto-common/lib/API/APIGen';

interface DashboardsCollectionsPageProps {
  segmentControl?: React.ReactNode;
}

const DashboardsCollectionsPage = ({
  segmentControl
}: DashboardsCollectionsPageProps) => {
  const [isOpen, showDialog, hideDialog] = useSimpleDialogState();

  const [defaultDialogIsOpen, showDefaultDialog, hideDefaultDialog] =
    useDialogState('select-default-dashboard-collection');
  const [currentItem, setCurrentItem] =
    useState<DashboardCollectionResponseModel>(null);
  const [reloadTrigger, triggerReload] = useReloadTrigger();

  if (!isOpen && currentItem != null) {
    setCurrentItem(null);
  }

  const setCurrentItemAndOpenDialog = useCallback(
    (item: DashboardCollectionResponseModel) => {
      setCurrentItem(item);
      showDialog();
    },
    [setCurrentItem, showDialog]
  );

  const _hideDialog = useCallback(() => {
    setCurrentItem(null);
    hideDialog();
  }, [hideDialog]);
  useEffect(() => {
    document.title = T.admin.dashboards.title;
  }, []);

  const toolbarItems = (
    <>
      {segmentControl}
      <ToolbarFlexibleSpace />
      <ToolbarItem>
        <Button onClick={showDefaultDialog}>
          <Icons.Folder /> {T.admin.dashboardcollection.setdefault}
        </Button>
      </ToolbarItem>
      <ToolbarItem>
        <AddButton onClick={showDialog}>
          {T.admin.dashboardcollection.add}
        </AddButton>
      </ToolbarItem>
    </>
  );

  return (
    <ToolbarContentPage
      wrapContent={false}
      title={T.admin.dashboards.title}
      showLocationPicker={false}
      toolbarItems={toolbarItems}
    >
      <DashboardsCollectionsPageList
        onClickRow={setCurrentItemAndOpenDialog}
        reloadTrigger={reloadTrigger}
      />

      <SelectDefaultDashboardCollectionDialog
        isOpen={defaultDialogIsOpen}
        onModalClose={hideDefaultDialog}
      />

      <DashboardCollectionFormModal
        currentItem={currentItem}
        onModalClose={_hideDialog}
        isOpen={isOpen}
        onSuccess={triggerReload}
      />
    </ToolbarContentPage>
  );
};

export default DashboardsCollectionsPage;
