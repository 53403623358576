import { createReducer } from 'ecto-common/lib/utils/reducerUtils';
import { createAction } from 'ecto-common/lib/utils/actionUtils';
import {
  instantiateBuildingTemplate,
  updateNodeTreeIncrementally
} from 'js/modules/provisioningCommon/provisioningCommon';
import { EquipmentTemplateFormActions } from 'js/modules/equipmentTemplateForm/equipmentTemplateForm';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { AdminDispatch, AdminGetState } from 'js/reducers/storeAdmin';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

const SET_INSTANTIATE_BUILDING_LOCATION = 'SET_INSTANTIATE_BUILDING_LOCATION';
const SET_INSTANTIATE_BUILDING_STATE = 'SET_INSTANTIATE_BUILDING_STATE';

/**
 * Holds instantiate template state. Each state represent client server interaction.
 */
export enum InstantiatingTemplateState {
  INSTANTIATING_TEMPLATE = 'INSTANTIATING_TEMPLATE',
  ADDING_CONNECTION = 'ADDING_CONNECTION',
  NODE_UPDATED = 'NODE_UPDATED',
  ERROR = 'ERROR'
}

type InstantiateEmptyBuildingReducerProps = {
  dialogIsOpen: boolean;
  location: SingleGridNode;
  loading: boolean;
  templateState: InstantiatingTemplateState | null;
};

const initialState: InstantiateEmptyBuildingReducerProps = {
  dialogIsOpen: false,
  location: null,
  loading: false,
  templateState: null
};

export default createReducer(initialState, {
  [SET_INSTANTIATE_BUILDING_LOCATION]: (_state, { location }) => {
    return { ...initialState, dialogIsOpen: location != null, location };
  },
  [SET_INSTANTIATE_BUILDING_STATE]: (state, { templateState }) => {
    let { dialogIsOpen } = state;

    if (
      templateState === InstantiatingTemplateState.NODE_UPDATED ||
      (templateState === InstantiatingTemplateState.ERROR &&
        state.templateState !==
          InstantiatingTemplateState.INSTANTIATING_TEMPLATE)
    ) {
      dialogIsOpen = false;
    }

    const nonLoadingStates = [
      null,
      InstantiatingTemplateState.ERROR,
      InstantiatingTemplateState.NODE_UPDATED
    ];

    return {
      ...state,
      loading: !nonLoadingStates.includes(templateState),
      dialogIsOpen,
      templateState
    };
  }
});

const _setLocationAction = createAction(
  SET_INSTANTIATE_BUILDING_LOCATION,
  'location'
);
const _setInstantiateState = createAction(
  SET_INSTANTIATE_BUILDING_STATE,
  'templateState'
);

export const InstantiateBuildingActions = {
  setLocation: (location: SingleGridNode) => {
    return (dispatch: AdminDispatch) => {
      dispatch(_setLocationAction(location));
      dispatch(EquipmentTemplateFormActions.resetForm());
      dispatch(EquipmentTemplateFormActions.showDialog(true));
    };
  },
  cancel: () => {
    return (dispatch: AdminDispatch) => {
      dispatch(_setLocationAction(null));
      dispatch(EquipmentTemplateFormActions.resetForm());
      dispatch(EquipmentTemplateFormActions.showDialog(false));
    };
  },
  performAdd: (contextSettings: ApiContextSettings, node: SingleGridNode) => {
    return async (dispatch: AdminDispatch, getState: AdminGetState) => {
      try {
        await dispatch(
          _setInstantiateState(
            InstantiatingTemplateState.INSTANTIATING_TEMPLATE
          )
        );
        const state = getState();
        await instantiateBuildingTemplate(contextSettings, node, state);
        await updateNodeTreeIncrementally(
          contextSettings,
          node.nodeId,
          dispatch
        );
        await dispatch(
          _setInstantiateState(InstantiatingTemplateState.NODE_UPDATED)
        );
        dispatch(EquipmentTemplateFormActions.showDialog(false));
      } catch (e) {
        await dispatch(_setInstantiateState(InstantiatingTemplateState.ERROR));
      }
    };
  }
};
