import React, { useCallback, useState } from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import T from 'ecto-common/lib/lang/Language';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import EditIoTDevice from 'js/components/EnergyManagers/EditIoTDevice';
import useEditIoTDevice from 'js/components/EnergyManagers/useEditIoTDevice';
import { IoTDeviceWithDeviceResponseModel } from 'ecto-common/lib/API/APIGen';
import RemoveDeviceModal from './RemoveDeviceModal';

interface EnergyManagerDetailsModalProps {
  detailDevice?: IoTDeviceWithDeviceResponseModel;
  isOpen?: boolean;
  onModalClose: () => void;
}

const EnergyManagerDetailsModal = ({
  detailDevice,
  isOpen,
  onModalClose
}: EnergyManagerDetailsModalProps) => {
  const [
    editDevice,
    hasChanges,
    loadingInfo,
    saveChanges,
    onDeviceDataChanged
  ] = useEditIoTDevice(detailDevice);
  const [deviceToDelete, setDeviceToDelete] = useState<string>(null);

  const onDeleteDevice = useCallback(() => {
    setDeviceToDelete(editDevice?.ioTDevice?.deviceName);
  }, [editDevice?.ioTDevice?.deviceName]);

  const title = detailDevice ? detailDevice.ioTDevice.deviceName : '';
  return (
    <>
      <ActionModal
        isOpen={isOpen}
        onModalClose={onModalClose}
        title={title}
        actionText={T.common.save}
        disableActionButton={!hasChanges}
        onConfirmClick={saveChanges}
        headerIcon={Icons.Edit}
        leftSideButton={
          <DeleteButton onClick={onDeleteDevice}>
            {T.admin.iotdevicedetails.deprovisiondevice}
          </DeleteButton>
        }
        wide
      >
        <EditIoTDevice
          loadingInfo={loadingInfo}
          editIoTDevice={editDevice}
          useMinimumHeight
          onDeviceDataChanged={onDeviceDataChanged}
        />
      </ActionModal>
      <RemoveDeviceModal
        deviceId={deviceToDelete}
        isOpen={deviceToDelete != null}
        onModalClose={() => setDeviceToDelete(null)}
        onDelete={() => {
          setDeviceToDelete(null);
          onModalClose?.();
        }}
      />
    </>
  );
};

export default EnergyManagerDetailsModal;
