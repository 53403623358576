import React from 'react';

import T from 'ecto-common/lib/lang/Language';
import SegmentControl from 'ecto-common/lib/SegmentControl/SegmentControl';
import { SegmentControlItem } from 'ecto-common/lib/SegmentControl/SegmentControlItem';
import Icons from 'ecto-common/lib/Icons/Icons';

import DashboardCollectionsPage from 'js/components/DashboardCollections/DashboardCollectionsPage';
import { useParams } from 'react-router-dom';
import DashboardsPage from 'js/components/Dashboards/DashboardsPage';
import EditDashboardPage from 'js/components/Dashboards/EditDashboardPage';
import { TemplateManagementParams } from 'js/utils/routeConstants';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';

const sections = [
  {
    title: T.admin.dashboards.title,
    key: 'dashboards',
    view: DashboardsPage,
    icon: <Icons.Dashboard />
  },
  {
    title: T.admin.dashboardcollection.title,
    key: 'collections',
    view: DashboardCollectionsPage,
    icon: <Icons.Folder />
  }
];

const Dashboards = () => {
  const params = useParams<TemplateManagementParams>();
  const dashboardId = params.itemId;

  const [curSection, setCurSection] = useSearchParamState(
    'section',
    'dashboards'
  );

  if (dashboardId != null) {
    return <EditDashboardPage dashboardId={dashboardId} />;
  }

  const segmentControl = (
    <SegmentControl>
      {sections.map((section) => (
        <SegmentControlItem
          key={section.key}
          active={curSection === section.key}
          onClick={() => setCurSection(section.key)}
        >
          {section.icon}
          {section.title}
        </SegmentControlItem>
      ))}
    </SegmentControl>
  );

  const activeSection =
    sections.find((section) => section.key === curSection) ?? sections[0];

  return (
    <>
      {React.createElement(activeSection.view, {
        segmentControl
      })}
    </>
  );
};

export default Dashboards;
