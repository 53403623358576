import React, {
  useCallback,
  useState,
  useMemo,
  Dispatch,
  SetStateAction
} from 'react';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';

import PagedDataTable, {
  PagedDataTableDataType,
  PagedDataTableErrorResult
} from 'ecto-common/lib/PagedDataTable/PagedDataTable';
import { DashboardFormColumnsWithLinks } from 'js/components/Dashboards/DashboardColumns';
import {
  calculateDataTableMinHeight,
  checkboxColumn,
  standardColumns,
  truncateColumns
} from 'ecto-common/lib/utils/dataTableUtils';
import APIGen, { DashboardResponseModel } from 'ecto-common/lib/API/APIGen';

interface DashboardsPageListProps {
  onPageChange?(newPage: number): void;
  onClickRow?(
    dashboard: DashboardResponseModel,
    row: number,
    column: number
  ): void;
  isMultipleSelect?: boolean;
  selectedDashboards?: DashboardResponseModel[];
  onSelectionChange?: Dispatch<SetStateAction<DashboardResponseModel[]>>;
  onDelete?(item: DashboardResponseModel, index: number): void;
  pageSize?: number;
  initialPage?: number;
}

const DashboardsPageList = ({
  onClickRow,
  onDelete,
  isMultipleSelect = false,
  selectedDashboards,
  onSelectionChange,
  pageSize = 15,
  initialPage,
  onPageChange
}: DashboardsPageListProps) => {
  const [internalPage, setPage] = useState(initialPage ?? 0);
  const page = useMemo(
    () => initialPage ?? internalPage,
    [initialPage, internalPage]
  );

  const columns = useMemo(
    () => [
      ...truncateColumns(DashboardFormColumnsWithLinks, isMultipleSelect),
      ...standardColumns<DashboardResponseModel>({
        onDelete
      })
    ],
    [onDelete, isMultipleSelect]
  );

  const getDashboardsQuery = APIGen.AdminDashboard.getPagedDashboards.useQuery({
    page,
    pageSize
  });
  const result: PagedDataTableDataType<DashboardResponseModel> = useMemo(() => {
    if (getDashboardsQuery.error != null) {
      return PagedDataTableErrorResult;
    }

    return {
      hasError: false,
      result: getDashboardsQuery.data?.dashboards ?? [],
      totalPages: getDashboardsQuery.data?.totalPages ?? 0,
      totalRecords: getDashboardsQuery.data?.totalRecords ?? 0
    };
  }, [
    getDashboardsQuery.data?.dashboards,
    getDashboardsQuery.data?.totalPages,
    getDashboardsQuery.data?.totalRecords,
    getDashboardsQuery.error
  ]);

  const checkAllVisibleItems = useCallback(() => {
    onSelectionChange?.((currentItems) => {
      const pageResult = result?.result;
      const intersection = _.intersectionBy(
        currentItems,
        pageResult,
        'dashboardId'
      );

      if (
        intersection.length > 0 &&
        intersection.length !== pageResult?.length
      ) {
        // Check all page items
        return _.unionBy(currentItems, pageResult, 'dashboardId');
      }

      // Toggle all page items
      return _.xorBy(currentItems, pageResult, 'dashboardId');
    });
  }, [onSelectionChange, result]);

  const _columns = isMultipleSelect
    ? [
        checkboxColumn({
          rowIsChecked: (item: DashboardResponseModel) =>
            _.find(selectedDashboards, { dashboardId: item.dashboardId }) !=
            null,
          checkAll: checkAllVisibleItems,
          checkAllIsChecked:
            _.intersectionBy(selectedDashboards, result?.result, 'dashboardId')
              .length === result?.result?.length
        }),
        ...columns
      ]
    : columns;

  const _onPageChange = useCallback(
    (newPage: number) => {
      setPage(newPage);
      onPageChange?.(newPage);
    },
    [onPageChange]
  );

  return (
    <>
      <PagedDataTable<DashboardResponseModel>
        columns={_columns}
        page={page}
        data={result}
        onPageChange={_onPageChange}
        pageSize={pageSize}
        noDataText={T.admin.dashboards.getdashboards.nodata}
        isLoading={getDashboardsQuery.isLoading}
        onClickRow={onClickRow}
        useAllAvailableHeight={!isMultipleSelect}
        minHeight={
          isMultipleSelect
            ? calculateDataTableMinHeight({
                pageSize,
                withCheckbox: true,
                withCheckboxInHeader: true
              })
            : null
        }
      />
    </>
  );
};

export default DashboardsPageList;
