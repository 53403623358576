import React, { useCallback } from 'react';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import { KeyValueFixedSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import SelectSignalTypesFromNodeTableDialog, {
  SelectSignalsFromNodeSignalType
} from 'js/components/SelectSignalsFromNodeTableDialog/SelectSignalTypesFromNodeTableDialog';
import styles from 'ecto-common/lib/ModelForm/ModelEditor.module.css';
import _ from 'lodash';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { CustomModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';

type AdminModelEditorNotificationAlarmSignalsProps = Omit<
  ModelEditorProps,
  'rawValue'
> & {
  nodeId?: string;
  rawValue: string[];
  selectLabel: React.ReactNode;
  model: CustomModelDefinition<object, object, unknown>;
};

const AdminModelEditorNotificationAlarmSignals = ({
  model,
  nodeId,
  updateItem,
  disabled,
  rawValue,
  selectLabel
}: AdminModelEditorNotificationAlarmSignalsProps) => {
  const [dialogIsOpen, showDialog, hideDialog] = useSimpleDialogState();

  const updateDialogItem = useCallback(
    (value: string[]) => {
      updateItem(value);
      hideDialog();
    },
    [updateItem, hideDialog]
  );

  const value = (
    <div>
      <Icons.Signal className={styles.icon} />
      {_.isEmpty(rawValue)
        ? T.admin.modeltypes.signals.zeroselected
        : T.format(T.admin.modeltypes.signals.numberselected, rawValue.length)}
    </div>
  );

  return (
    <>
      <KeyValueFixedSelectableInput
        disabled={disabled}
        keyText={model.label}
        value={value}
        placeholder={_.defaultTo(selectLabel, model.label)}
        onClick={showDialog}
      />
      <SelectSignalTypesFromNodeTableDialog
        isOpen={dialogIsOpen}
        onModalClose={hideDialog}
        nodeId={nodeId}
        type={SelectSignalsFromNodeSignalType.NOTIFICATION_ALARM_SIGNALS}
        selectedSignals={_.map(rawValue, (signalTypeId) => ({ signalTypeId }))}
        onSignalsSelected={updateDialogItem}
      />
    </>
  );
};

export default React.memo(AdminModelEditorNotificationAlarmSignals);
