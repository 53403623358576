import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useRef,
  useContext
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import { NavLink } from 'react-router-dom';

import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import API from 'ecto-common/lib/API/API';
import { AlarmSignalGroupTemplateIds } from 'ecto-common/lib/utils/constants';
import HelpPaths from 'ecto-common/help/tocKeys';
import { hasFalsyProperty } from 'ecto-common/lib/utils/functional';

import { transformModbusDataTypes } from 'js/components/ModbusLayout/ModbusEditUtils';
import { signalProviderInputs } from 'js/components/ManageTemplates/signalInputs';
import EditSignalProviderType from 'js/components/ManageTemplates/EditSignalProviderType/EditSignalProviderType';
import {
  TemplateManagementParams,
  getTemplateManagementRoute
} from 'js/utils/routeConstants';
import { patchSignalTemplates } from 'js/actions/getSignalTemplates';
import HttpStatus from 'ecto-common/lib/utils/HttpStatus';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import {
  AddOrUpdateAlarmSignalGroupTemplateRequestModel,
  AdminAlarmSignalGroupTemplateResponseModel,
  AdminAlarmSignalTemplateRequestModel,
  SignalProviderType
} from 'ecto-common/lib/API/APIGen';
import { AlarmOrEqTemplate } from 'js/components/ManageTemplates/manageTemplatesTypes';

const signalInputProvider = signalProviderInputs[SignalProviderType.Alarm];

const getAlarmName = (
  id: string,
  templates: AdminAlarmSignalGroupTemplateResponseModel[]
): string => _.find(templates, { alarmSignalGroupTemplateId: id })?.name ?? '';

const hideSignalProperties = [
  'dataFormat',
  'signalDirection',
  'graphicalRepresentation',
  'signalCategoryIds'
];

// These are system level signal templates that are seeded and have to exist.
// TODO: There should be a better mechanism of dealing with these
const readOnlySignalTemplateIds = [
  'b63502ef-c288-4b29-8fc4-dd763750313d', // EM alarmBMS
  '43c667bb-4a36-4938-ad2a-adb30c9319b1', // EM alarmEM
  '23bb4f1a-fc95-559c-8ad1-f5bc9753e9e7', // EM alarmEnergyManagerOffline
  'daa7e22b-49fd-4de4-a47f-275a1725eaa3', // EM alarmModbus
  'd17140c0-15b2-4167-83c3-069de4b9607a', // EM alarmWatchdogBMS
  'b63502ef-c288-4b29-8fc4-dd763750313d' // EM alarmBMS
];

const allowDeleteForSignal = (signal: AlarmOrEqTemplate) => {
  return !readOnlySignalTemplateIds.includes(signal.id);
};

const EditAlarmSignalGroupTemplate = () => {
  const dispatch = useAdminDispatch();
  const params = useParams<TemplateManagementParams>();
  const navigate = useNavigate();
  const alarmSignalTemplates = useAdminSelector(
    (state) => state.admin.signalTemplates.alarmSignalTemplates
  );

  const [editedSignalTemplates, setEditedSignalTemplates] =
    useState<AlarmOrEqTemplate[]>(null);
  const [editedName, setEditedName] = useState(
    getAlarmName(params.itemId, alarmSignalTemplates)
  );
  const [originalName, setOriginalName] = useState(
    getAlarmName(params.itemId, alarmSignalTemplates)
  );
  const [selectedTemplate, setSelectedTemplate] =
    useState<AdminAlarmSignalGroupTemplateResponseModel>(null);
  const { tenantId } = useContext(TenantContext);

  const [getTemplatesIsLoading, getTemplates] = usePromiseCall({
    promise: API.Admin.Templates.getAlarmTemplateByAlarmSignalGroupTemplateId,
    initiallyLoading: true,
    onSuccess: (response) => {
      setSelectedTemplate(response);
      setOriginalName(response.name);
      setEditedSignalTemplates(null);
      setEditedName(response.name);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.equipmenttemplates.error.failedtofetch);
    }
  });

  useEffect(() => {
    getTemplates(params?.itemId);
  }, [getTemplates, params?.itemId]);

  const prevSubPageRef = useRef(undefined);
  useEffect(() => {
    if (prevSubPageRef.current !== params.subPage) {
      setEditedName(originalName);
      setEditedSignalTemplates(null);

      prevSubPageRef.current = params.subPage;
    }
  }, [originalName, params.subPage]);

  const getOutput =
    useCallback((): AddOrUpdateAlarmSignalGroupTemplateRequestModel => {
      const name = editedName || selectedTemplate?.name;

      if (editedSignalTemplates === null) {
        // Cast due to mismatches in optionality between response signal configurations and addorupdate model
        return {
          ...selectedTemplate,
          name
        } as AddOrUpdateAlarmSignalGroupTemplateRequestModel;
      }

      const templates = transformModbusDataTypes(
        _.cloneDeep(editedSignalTemplates)
      );

      // Do cast to bridge over API model differences
      return {
        ...selectedTemplate,
        alarmSignalTemplates:
          templates as AdminAlarmSignalTemplateRequestModel[],
        name
      };
    }, [editedName, editedSignalTemplates, selectedTemplate]);

  // TODO: Use blocking call for saving alarm templates?
  const [isSavingDetails, saveDetailsCall] = usePromiseCall({
    promise: API.Admin.Templates.updateAlarmTemplate,
    onSuccess: (result) => {
      dispatch(patchSignalTemplates([result]));

      setSelectedTemplate(result);
      setOriginalName(result.name);
      setEditedSignalTemplates(null);
      setEditedName(result.name);
      toastStore.addSuccessToast(T.admin.requests.updatetemplate.success);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.equipmenttemplates.error.updatetemplate);
    }
  });

  const saveDetails = useCallback(() => {
    saveDetailsCall(getOutput());
  }, [saveDetailsCall, getOutput]);

  const invalidTemplates = useMemo(() => {
    const output = getOutput();
    return _.filter(
      output.alarmSignalTemplates,
      hasFalsyProperty('signalTypeId', 'alarmType', 'name')
    );
  }, [getOutput]);

  const saveTemplate = useCallback(() => {
    const output = getOutput();

    if (invalidTemplates.length > 0) {
      toastStore.addErrorToast(T.admin.equipmenttemplates.error.missingfields);
    } else {
      saveDetailsCall(output);
    }
  }, [getOutput, invalidTemplates.length, saveDetailsCall]);

  const [isDeletingTemplate, deleteTemplateCall] = usePromiseCall({
    promise: API.Admin.Templates.deleteAlarm,
    onSuccess: () => {
      toastStore.addSuccessToast(T.admin.templates.removed);
      navigate(getTemplateManagementRoute(tenantId, 'alarms'), {
        replace: true
      });
    },
    onError: (e) => {
      if (e?.response?.status === HttpStatus.FORBIDDEN) {
        toastStore.addErrorToast(
          T.admin.alarmtemplates.error.couldnotremoveconflict
        );
      } else {
        toastStore.addErrorToast(T.admin.alarmtemplates.error.couldnotremove);
      }
    }
  });

  const deleteTemplate = useCallback(() => {
    if (params.itemId === AlarmSignalGroupTemplateIds.ENERGY_MANAGER_ALARMS) {
      toastStore.addErrorToast(T.admin.alarmtemplates.error.notallowed);
    } else {
      deleteTemplateCall([params.itemId]);
    }
  }, [deleteTemplateCall, params.itemId]);

  const isLoading =
    getTemplatesIsLoading || isSavingDetails || isDeletingTemplate;

  const link = useMemo(
    () => (
      <span>
        <NavLink to={`/${tenantId}/templateManagement/alarms`}>
          {T.admin.alarmtemplates.header}
        </NavLink>{' '}
        &gt; {selectedTemplate?.name}
      </span>
    ),
    [selectedTemplate?.name, tenantId]
  );
  useEffect(() => {
    document.title = `${T.admin.alarmtemplates.header} > ${selectedTemplate?.name}`;
  }, [selectedTemplate?.name]);

  const onSignalsChanged = useCallback((templates: AlarmOrEqTemplate[]) => {
    setEditedSignalTemplates(templates);
  }, []);

  const onTemplateNameChanged = useCallback((name: string) => {
    setEditedName(name);
  }, []);

  const hasNoData = selectedTemplate == null;

  return (
    <EditSignalProviderType
      editedSignalTemplates={editedSignalTemplates}
      initialSignals={selectedTemplate?.alarmSignalTemplates}
      isLoading={isLoading}
      onDeleteTemplate={deleteTemplate}
      onSaveDetails={saveDetails}
      onSaveTemplates={saveTemplate}
      onSignalsChanged={onSignalsChanged}
      onTemplateNameChanged={onTemplateNameChanged}
      originalName={originalName}
      signalInputProvider={signalInputProvider}
      signalInputsToHideForTable={hideSignalProperties}
      signalProviderType={SignalProviderType.Alarm}
      templateName={editedName}
      title={link}
      allowDeleteForSignal={allowDeleteForSignal}
      hasNoData={hasNoData}
      helpPath={HelpPaths.docs.admin.templates.alarms_types}
    />
  );
};

export default EditAlarmSignalGroupTemplate;
