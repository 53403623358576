import { createReducer } from 'ecto-common/lib/utils/reducerUtils';
import { createAction } from 'ecto-common/lib/utils/actionUtils';
import API, {
  performAsyncRequestWithStateChanges
} from 'ecto-common/lib/API/API';
import {
  initialReqState,
  RequestStatusRawProp,
  updateReqState
} from 'ecto-common/lib/utils/requestStatus';
import {
  AddOrUpdateConnectionModbusConfigByTemplateRequestModel,
  EquipmentTemplateInstanceOverrideRequestModel
} from 'ecto-common/lib/API/APIGen';
import {
  AdminDispatch,
  AdminGetState,
  AdminRootState
} from 'js/reducers/storeAdmin';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

const SET_TEMPLATE_FORM_RESET_FORM = 'SET_TEMPLATE_FORM_RESET_FORM';
const SET_TEMPLATE_FORM_EQUIPMENT_TEMPLATE_GROUP_ID =
  'SET_TEMPLATE_FORM_EQUIPMENT_TEMPLATE_GROUP_ID';
const SET_TEMPLATE_FORM_EXISTING_ENERGY_MANAGER_NODE_ID =
  'SET_TEMPLATE_FORM_EXISTING_ENERGY_MANAGER_NODE_ID';
const SET_TEMPLATE_FORM_EXISTING_ENERGY_MANAGER_DEVICE_ID =
  'SET_TEMPLATE_FORM_EXISTING_ENERGY_MANAGER_DEVICE_ID';
const SET_TEMPLATE_FORM_CONNECTION_MODBUS_CONFIG_OVERRIDE =
  'SET_TEMPLATE_FORM_CONNECTION_MODBUS_CONFIG_OVERRIDE';
const SET_TEMPLATE_FORM_EQUIPMENT_TEMPLATE_INSTANCE_OVERRIDES =
  'SET_TEMPLATE_FORM_EQUIPMENT_TEMPLATE_INSTANCE_OVERRIDES';
const SET_TEMPLATE_FORM_SHOW_CONNECTION_DIALOG =
  'SET_TEMPLATE_FORM_SHOW_CONNECTION_DIALOG';
const SET_TEMPLATE_FORM_SHOW_DEVICE_DIALOG =
  'SET_TEMPLATE_FORM_SHOW_DEVICE_DIALOG';
const SET_TEMPLATE_FORM_SHOW_DIALOG = 'SET_TEMPLATE_FORM_SHOW_DIALOG';
const SET_TEMPLATE_FORM_INIT_DEVICE_CONFIG_REQ_STATE =
  'SET_TEMPLATE_FORM_INIT_DEVICE_CONFIG_REQ_STATE';

interface EquipmentTemplateReducerProps {
  existingEnergyManagerNodeId: string;
  existingEnergyManagerDeviceId: string;
  equipmentTemplateGroupId: string;
  connectionModbusConfigOverride: AddOrUpdateConnectionModbusConfigByTemplateRequestModel;
  equipmentTemplateInstanceOverrides: EquipmentTemplateInstanceOverrideRequestModel[];
  showDeviceDialog: boolean;
  showConnectionDialog: boolean;
  showDialog: boolean;
  initDeviceConfigReqState: RequestStatusRawProp<void>;
}

export const initTemplateInitialState: EquipmentTemplateReducerProps = {
  existingEnergyManagerNodeId: null,
  existingEnergyManagerDeviceId: null,
  equipmentTemplateGroupId: null,
  connectionModbusConfigOverride: {},
  equipmentTemplateInstanceOverrides: [],
  showDeviceDialog: false,
  showConnectionDialog: false,
  showDialog: false,
  initDeviceConfigReqState: initialReqState
};

export default createReducer(initTemplateInitialState, {
  [SET_TEMPLATE_FORM_RESET_FORM]: () => {
    return Object.assign({}, initTemplateInitialState);
  },
  [SET_TEMPLATE_FORM_EQUIPMENT_TEMPLATE_GROUP_ID]: (
    state,
    { equipmentTemplateGroupId }
  ) => {
    return { ...state, equipmentTemplateGroupId };
  },
  [SET_TEMPLATE_FORM_EXISTING_ENERGY_MANAGER_NODE_ID]: (
    state,
    { existingEnergyManagerNodeId }
  ) => {
    if (existingEnergyManagerNodeId) {
      return {
        ...state,
        existingEnergyManagerNodeId,
        initDeviceConfigReqState: initialReqState,
        showConnectionDialog: false
      };
    }

    return { ...state, existingEnergyManagerNodeId };
  },
  [SET_TEMPLATE_FORM_EXISTING_ENERGY_MANAGER_DEVICE_ID]: (
    state,
    { existingEnergyManagerDeviceId }
  ) => {
    return { ...state, existingEnergyManagerDeviceId };
  },
  [SET_TEMPLATE_FORM_SHOW_DEVICE_DIALOG]: (state, { showDeviceDialog }) => {
    return { ...state, showDeviceDialog };
  },
  [SET_TEMPLATE_FORM_SHOW_DIALOG]: (state, { showDialog }) => {
    return { ...state, showDialog };
  },
  [SET_TEMPLATE_FORM_INIT_DEVICE_CONFIG_REQ_STATE]: (state, payload) => {
    return {
      ...state,
      initDeviceConfigReqState: updateReqState(
        state.initDeviceConfigReqState,
        payload
      )
    };
  },
  [SET_TEMPLATE_FORM_CONNECTION_MODBUS_CONFIG_OVERRIDE]: (
    state,
    { connectionModbusConfigOverride }
  ) => {
    return { ...state, connectionModbusConfigOverride };
  },
  [SET_TEMPLATE_FORM_EQUIPMENT_TEMPLATE_INSTANCE_OVERRIDES]: (
    state,
    { overrides }
  ) => {
    return { ...state, equipmentTemplateInstanceOverrides: overrides };
  },
  [SET_TEMPLATE_FORM_SHOW_CONNECTION_DIALOG]: (
    state,
    { showConnectionDialog }
  ) => {
    return { ...state, showConnectionDialog };
  }
});

const _addDefaultConnectionSettings = async (
  contextSettings: ApiContextSettings,
  existingEnergyManagerNodeId: string,
  equipmentTemplateForm: AdminRootState['equipmentTemplateForm'],
  reuseSettings: boolean,
  dispatch: AdminDispatch
) => {
  const { slaveId } = await API.Admin.Devices.getSuggestedSlaveId(
    contextSettings,
    existingEnergyManagerNodeId
  );
  let connectionModbusConfigOverride;
  if (reuseSettings) {
    connectionModbusConfigOverride = {
      ...equipmentTemplateForm.connectionModbusConfigOverride,
      slaveId
    };
  } else {
    const baseConnectionInfo = await API.Admin.Devices.getConnections(
      contextSettings,
      [existingEnergyManagerNodeId]
    );
    connectionModbusConfigOverride = {
      ...equipmentTemplateForm.connectionModbusConfigOverride,
      ...baseConnectionInfo[0].connectionModbusConfig,
      slaveId
    };
    delete connectionModbusConfigOverride.id;
    delete connectionModbusConfigOverride.modbusVersionSignalId;
    delete connectionModbusConfigOverride.watchdogModbusAlarmSignalId;
  }

  dispatch(
    EquipmentTemplateFormActions.setConnectionModbusConfigOverride(
      connectionModbusConfigOverride
    )
  );
};

export const EquipmentTemplateFormActions = {
  resetForm: createAction(SET_TEMPLATE_FORM_RESET_FORM),
  setEquipmentTemplateGroupId: createAction(
    SET_TEMPLATE_FORM_EQUIPMENT_TEMPLATE_GROUP_ID,
    'equipmentTemplateGroupId'
  ),
  updateFormAfterBuildingAdded: (contextSettings: ApiContextSettings) => {
    return async (dispatch: AdminDispatch, getState: AdminGetState) => {
      const { equipmentTemplateForm } = getState();
      const { existingEnergyManagerNodeId } = equipmentTemplateForm;

      if (existingEnergyManagerNodeId) {
        await performAsyncRequestWithStateChanges(
          dispatch,
          async () => {
            await _addDefaultConnectionSettings(
              contextSettings,
              existingEnergyManagerNodeId,
              equipmentTemplateForm,
              true,
              dispatch
            );
          },
          SET_TEMPLATE_FORM_INIT_DEVICE_CONFIG_REQ_STATE
        );
      }
    };
  },
  setExistingEnergyManagerNodeId: (
    contextSettings: ApiContextSettings,
    existingEnergyManagerNodeId: string
  ) => {
    return async (dispatch: AdminDispatch, getState: AdminGetState) => {
      dispatch({
        type: SET_TEMPLATE_FORM_EXISTING_ENERGY_MANAGER_NODE_ID,
        payload: { existingEnergyManagerNodeId }
      });
      const { equipmentTemplateForm } = getState();

      if (existingEnergyManagerNodeId) {
        await performAsyncRequestWithStateChanges(
          dispatch,
          async () => {
            const deviceInfo = await API.Admin.Devices.getDeviceInfo(
              contextSettings,
              existingEnergyManagerNodeId
            );
            const { deviceId } = deviceInfo;
            dispatch(
              EquipmentTemplateFormActions.setExistingEnergyManagerDeviceId(
                deviceId
              )
            );
            await _addDefaultConnectionSettings(
              contextSettings,
              existingEnergyManagerNodeId,
              equipmentTemplateForm,
              false,
              dispatch
            );
          },
          SET_TEMPLATE_FORM_INIT_DEVICE_CONFIG_REQ_STATE
        );
      } else {
        dispatch(
          EquipmentTemplateFormActions.setExistingEnergyManagerDeviceId(null)
        );
        dispatch(
          EquipmentTemplateFormActions.setConnectionModbusConfigOverride({})
        );
      }
    };
  },
  setExistingEnergyManagerDeviceId: createAction(
    SET_TEMPLATE_FORM_EXISTING_ENERGY_MANAGER_DEVICE_ID,
    'existingEnergyManagerDeviceId'
  ),
  setConnectionModbusConfigOverride: createAction(
    SET_TEMPLATE_FORM_CONNECTION_MODBUS_CONFIG_OVERRIDE,
    'connectionModbusConfigOverride'
  ),
  setEquipmentTemplateOverrides: createAction(
    SET_TEMPLATE_FORM_EQUIPMENT_TEMPLATE_INSTANCE_OVERRIDES,
    'overrides'
  ),
  showDeviceDialog: createAction(
    SET_TEMPLATE_FORM_SHOW_DEVICE_DIALOG,
    'showDeviceDialog'
  ),
  showDialog: createAction(SET_TEMPLATE_FORM_SHOW_DIALOG, 'showDialog'),
  showConnectionDialog: createAction(
    SET_TEMPLATE_FORM_SHOW_CONNECTION_DIALOG,
    'showConnectionDialog'
  )
};
